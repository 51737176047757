import SingleLegendIdentifier from "./SingleLegendIdentifier";
import LegendsTypeContainer from "./LegendsTypeContainer";
export default function LegendsIdentifier({
    legendsData,
    heading,
    legendType,
}) {
    const legendElements = legendsData.map((entry) => (
        <SingleLegendIdentifier
            name={entry.name}
            style={legendType.color ? entry.color : entry.style}
            key={entry.name}
            legendType={legendType}
        />
    ));

    return (
        <LegendsTypeContainer heading={heading}>
            {legendElements}
        </LegendsTypeContainer>
    );
}
