import React from "react";
import { ReactComponent as ErrorIcon } from "./../assets/uploadFileIcons/error.svg";
export default function ErrorCom() {
    return (
        <>
            <div className="flex justify-center items-center absolute w-full h-full">
                <div className="w-full h-full absolute bg-gray-700 z-1 opacity-20 flex justify-center items-center"></div>
                <div className="absolute z-20 opacity-100 bg-white shadow-xl rounded-md p-5 px-9 flex flex-col items-center gap-6">
                    <div className="flex flex-col justify-center items-center gap-2">
                        <ErrorIcon className="w-[70px] h-[70px] fill-gray-300" />
                        <h1>Something went wrong!</h1>
                    </div>
                    <button
                        className={`bg-primary text-white py-1 px-6 rounded-md`}
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        {"Try Again"}
                    </button>
                </div>
            </div>
        </>
    );
}
