import { useEffect, useState } from "react";
import { ReactComponent as CancelIcon } from "../../assets/insightsIcons/cancel-cross.svg";
import { ReactComponent as BackArrow } from "../../assets/insightsIcons/backward-arrow.svg";
import { ReactComponent as ForwardArrow } from "../../assets/insightsIcons/forward-arrow.svg";
import { get_golden_time } from "../../services/api-calls";

const GoldenTimeList = ({ toggleShowGoldenTimeList }) => {
    const [goldenTimePage, setGoldenTimePage] = useState({ page_number: 1 });
    const [goldenTimeLastPage, setGoldenTimeLastPage] = useState(null);
    const [goldenTimeData, setGoldenTimeData] = useState(null);
    useEffect(() => {
        async function getGoldenTime() {
            try {
                const goldenTimeResponse = await get_golden_time(
                    goldenTimePage
                );
                setGoldenTimeLastPage(goldenTimeResponse.data.total_pages);
                setGoldenTimeData(goldenTimeResponse.data.golden_cycle);
            } catch (e) {
                console.log(e);
            }
        }
        getGoldenTime();
    }, [goldenTimePage]);

    const goldenTimeElements = goldenTimeData?.map((entry, index) => (
        <>
            <p
                className={`h-full w-full flex items-center px-3 py-2 ${
                    index % 2 === 0 && "bg-bgColor1"
                }`}
            >
                {entry.asset}, {entry.product}, {entry.phase}
            </p>

            <p
                className={`h-full w-full flex items-center px-3 justify-center ${
                    index % 2 === 0 && "bg-bgColor1"
                }`}
            >
                {entry.phase_golden_duration}
            </p>
        </>
    ));

    return (
        <div
            className={
                "bg-insightsBackground rounded-[11px] p-3 flex flex-col items-center absolute right-0 w-full h-[1050px]"
            }
        >
            <div className="flex items-center gap-2 w-full">
                <p className="mr-auto text-textColor1">Golden Duration</p>
                <CancelIcon
                    className="w-[14px] cursor-pointer"
                    onClick={toggleShowGoldenTimeList}
                />
            </div>
            <div className="mt-3 text-sm grid grid-flow-row grid-cols-[50%_50%] auto-rows-[minmax(40px,auto)] w-full bg-white rounded-[11px] border border-insightsBorder pb-4 pt-3 shadow-[0px_3px_1px_#00000021] text-textColor mb-auto overflow-y-auto gap-y-1">
                <p className="px-3 h-full w-full flex items-center pb-2">
                    Asset, Product, Phase
                </p>
                <p className="px-3 h-full w-full flex items-center justify-center">
                    Golden Time
                </p>
                {goldenTimeElements}
            </div>
            <div className="flex items-center gap-2 mt-4">
                <BackArrow
                    className={`w-[10px] cursor-pointer ${
                        goldenTimePage.page_number === 1 &&
                        "pointer-events-none fill-[#bbb]"
                    }`}
                    onClick={() =>
                        setGoldenTimePage((prevGoldenTimePage) => ({
                            page_number: prevGoldenTimePage.page_number - 1,
                        }))
                    }
                />
                <p className="text-textColor">{`${goldenTimePage.page_number} of ${goldenTimeLastPage}`}</p>
                <ForwardArrow
                    className={`w-[10px] cursor-pointer ${
                        goldenTimePage.page_number === goldenTimeLastPage &&
                        "pointer-events-none fill-[#bbb]"
                    }`}
                    onClick={() =>
                        setGoldenTimePage((prevGoldenTimePage) => ({
                            page_number: prevGoldenTimePage.page_number + 1,
                        }))
                    }
                />
            </div>
        </div>
    );
};

export default GoldenTimeList;
