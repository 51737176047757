import React, { useEffect, useState } from "react";
import ConvertDate from "../../helpers/convertDate";
import { ReactComponent as UpArrow } from "../../assets/uploadFileIcons/arrow-up.svg";
import ActivateFileModal from "./ActivateFileModal";
import TruncateString from "../../components/TruncateString";

export default function FileListTable(props) {
    const { fileList, setFileList, getFiles } = props;
    const [isSortByName, setIsSortByName] = useState(false);
    const [isSortByDate, setIsSortByDate] = useState(true);
    const [showActivateModal, setActivateModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [nameIsAscending, setNameIsAscending] = useState(false);
    const [dateIsAscending, setDateIsAscending] = useState(false);

    const sortByName = () => {
        setIsSortByDate(false);
        setIsSortByName(true);
        setNameIsAscending(!nameIsAscending);
        let sortFile = fileList.sort((a, b) => {
            const nameA = a.original_file_name.toUpperCase();
            const nameB = b.original_file_name.toUpperCase();
            if (nameA < nameB) {
                return nameIsAscending ? -1 : 1; // Return 1 for descending order
            }

            if (nameA > nameB) {
                return nameIsAscending ? 1 : -1; // Return -1 for descending order
            }
            return 0;
        });
        setFileList((prevState) => [...sortFile]);
    };
    const sortByDate = () => {
        setIsSortByName(false);
        setIsSortByDate(true);
        setDateIsAscending(!dateIsAscending);
        let sortFile = fileList.sort((a, b) => {
            let a1 = new Date(a.created_at);
            let b1 = new Date(b.created_at);
            if (dateIsAscending === true) {
                return b1 - a1; // for descending
            } else {
                return a1 - b1; // for ascending
            }
        });
        setFileList((prevState) => [...sortFile]);
    };
    const selectFile = (fileObj) => {
        setSelectedFile(fileObj);
        setActivateModal(true);
    };
    return (
        <div className=" mt-5">
            <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                        <div class="overflow-hidden">
                            <table class="min-w-full text-left text-sm font-light">
                                <thead class="border-b font-medium dark:border-neutral-400">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-4 flex items-center"
                                        >
                                            <span className="hover:rounded-2xl mx-1 px-2 py-1">
                                                File Name
                                            </span>
                                            <UpArrow
                                                onClick={() => sortByName()}
                                                className={` w-7 h-7 cursor-pointer rounded-full p-2 hover:bg-gray-200 ${
                                                    isSortByName
                                                        ? "bg-gray-200"
                                                        : ""
                                                } ${
                                                    nameIsAscending
                                                        ? "rotate-180"
                                                        : ""
                                                }`}
                                            />
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            File type
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Description
                                        </th>
                                        <th scope="col" className="px-6 py-4">
                                            Uploaded by
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-4 flex items-center"
                                        >
                                            <span className="hover:rounded-2xl mx-1 px-2 py-1">
                                                {" "}
                                                Date/Time
                                            </span>
                                            <UpArrow
                                                onClick={() => sortByDate()}
                                                className={` w-7 h-7 cursor-pointer rounded-full p-2 hover:bg-gray-200 ${
                                                    isSortByDate
                                                        ? "bg-gray-200"
                                                        : ""
                                                } ${
                                                    dateIsAscending
                                                        ? ""
                                                        : "rotate-180"
                                                }`}
                                            />
                                        </th>
                                        {/*  <th scope="col" className="px-6 py-4">Size</th> */}
                                        <th scope="col" className="px-6 py-4">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    className={`${
                                        fileList?.length === 0
                                            ? " relative h-8"
                                            : ""
                                    }`}
                                >
                                    {fileList?.length !== 0 ? (
                                        fileList?.map((row, i) => (
                                            <tr
                                                key={i}
                                                className={`hover:bg-gray-100`}
                                                // title={row.file_type}
                                            >
                                                <td
                                                    data-tooltip-target="tooltip-default"
                                                    class="whitespace-nowrap px-6 py-4 break-all font-medium text-green-700"
                                                >
                                                    <TruncateString text={row?.original_file_name} maxLength={35} />
                                                </td>
                                                <td class="whitespace-nowrap px-6 py-4 text-[#616161]">
                                                    {row?.file_type}
                                                </td>
                                                <td className="whitespace-nowrap  px-6 py-4 text-[#616161]">
                                                    {row?.description ? (
                                                         <TruncateString text={row?.description} maxLength={40} />
                                                        // <span>
                                                        //     {row?.description}
                                                        // </span>
                                                    ) : (
                                                        <span className=" text-gray-400">
                                                            No description added
                                                        </span>
                                                    )}
                                                </td>
                                                <td className="whitespace-nowrap px-6 py-4 text-green-700">
                                                    {row?.created_by}
                                                </td>
                                                <td className="whitespace-nowrap px-6 py-4 text-[#616161]">
                                                    {ConvertDate(
                                                        row?.created_at
                                                    )}
                                                </td>
                                                <td className="whitespace-nowrap px-6 py-4 text-[#616161]">
                                                    {row.is_active === true ? (
                                                        <span
                                                            className={`text-green-700`}
                                                        >
                                                            Active
                                                        </span>
                                                    ) : (
                                                        <span
                                                            className="underline cursor-pointer"
                                                            onClick={() =>
                                                                selectFile(row)
                                                            }
                                                        >
                                                            Activate
                                                        </span>
                                                    )}
                                                </td>
                                                {/* <td class="whitespace-nowrap px-6 py-4">{row?.desc}</td> */}
                                            </tr>
                                        ))
                                    ) : (
                                        <div className=" w-full text-center flex justify-center p-3  absolute">
                                            {" "}
                                            Nothing to show{" "}
                                        </div>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {showActivateModal === true ? (
                <ActivateFileModal
                    setActivateModal={setActivateModal}
                    selectedFile={selectedFile}
                    getFiles={getFiles}
                />
            ) : (
                ""
            )}
        </div>
    );
}
