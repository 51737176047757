import { useContext, useState } from "react";
import { ReactComponent as DownwardArrow } from "../assets/insightsIcons/downward-arrow.svg";
import { ReactComponent as UpwardArrow } from "../assets/insightsIcons/upward-arrow.svg";
import { ChartsContext } from "../contexts/ChartsContext";
import { Context } from "../contexts/Context";
import DatePickerPopUp from "./DatePickerPopUp";

export default function DateRangeContainer({
    showDateRange,
    toggleDateRange,
    menuRef,
    setShowDateRange,
    chartType,
    setShowCustomRangeMenu,
    showCustomRangeMenu,
    dateParams,
    setDateParams,
}) {
    const {
        // dateParams,
        // setDateParams,
        dataEndLimit,
        setIsDateChange,
        dataStartLimit,
        setCustomRange,
        customRange,
        setGraphStartDate,
        setGraphEndDate,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
    } = useContext(ChartsContext);
    const { isLoading } = useContext(Context);
    const [nonCustomValue, setNonCustomValue] = useState(null);

    function handleDateRangeDays(e) {
        setDateParams(e.target.value);
        setIsDateChange(true);
        if (e.target.value !== "custom") {
            const dateRangeObject = {
                start_date: new Date(
                    new Date(dataEndLimit).setDate(
                        new Date(dataEndLimit).getDate() -
                            parseInt(e.target.value) +
                            1
                    )
                ).toLocaleString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                }),
                end_date: new Date(dataEndLimit).toLocaleString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                }),
            };
            setCustomRange(dateRangeObject);
            setGraphStartDate(
                new Date(dateRangeObject.start_date).setHours(0, 0, 0)
            );
            setGraphEndDate(
                new Date(
                    new Date(dateRangeObject.end_date).setDate(
                        new Date(dateRangeObject.end_date).getDate() + 1
                    )
                ).setHours(0, 0, 0)
            );
            setNonCustomValue(
                dateRangeData[chartType].filter(
                    (option) => parseInt(e.target.value) === option.value
                )[0].topHeading
            );
        }
    }

    function handleOptionClick(e) {
        if (e.target.value !== "custom") {
            setShowDateRange(false);
        } else {
            setShowCustomRangeMenu(true);
            setShowDateRange(false);
        }
    }
    function handleCancel() {
        setShowDateRange(false);
        setShowCustomRangeMenu(false);
    }

    function changeDateRange() {
        setIsDateChange(true);
        const dateRangeObject = {
            start_date: startDate.toLocaleString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
            }),
            end_date: new Date(endDate).toLocaleString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
            }),
        };
        setCustomRange(dateRangeObject);
        setGraphEndDate(
            new Date(
                new Date(endDate).setDate(new Date(endDate).getDate() + 1)
            ).setHours(0, 0, 0)
        );
        setGraphStartDate(startDate);
        setShowCustomRangeMenu(false);
        setNonCustomValue(null);
    }

    const endDateString = new Date(dataEndLimit).toLocaleString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
    });
    const startDateForThreeDays = new Date(
        new Date(dataEndLimit)?.setDate(new Date(dataEndLimit)?.getDate() - 2)
    ).toLocaleString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
    });
    const startDateForFiveDays = new Date(
        new Date(dataEndLimit)?.setDate(new Date(dataEndLimit)?.getDate() - 4)
    ).toLocaleString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
    });
    const dateRangeData = {
        Visualizer: [
            {
                topHeading: "Last 1 Day",
                bottomHeading: endDateString,
                value: 1,
            },
            {
                topHeading: "Last 3 Days",
                bottomHeading: `${startDateForThreeDays} - ${endDateString}`,
                value: 3,
            },
            {
                topHeading: "Last 5 Days",
                bottomHeading: `${startDateForFiveDays} - ${endDateString}`,
                value: 5,
            },
            {
                topHeading: "Custom",
                bottomHeading: `${startDate?.toLocaleString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                })} - ${endDate?.toLocaleString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                })}`,
                value: "custom",
            },
        ],
        Pareto: [
            {
                topHeading: "Last 1 Week",
                bottomHeading: "26th Sep, 2022 - 30th Sep, 2022",
                value: 1,
            },
        ],
        Cycletime: [
            {
                topHeading: "Last 6 Months",
                bottomHeading: "1st Jul, 2022 - 30th Sep, 2022",
                value: 6,
            },
            {
                topHeading: "Last 12 Months",
                bottomHeading: "1st Oct, 2021 - 30th Sep, 2022",
                value: 12,
            },
            {
                topHeading: "Last 15 Months",
                bottomHeading: "18th Jun, 2021 - 30th Sep, 2022",
                value: 15,
            },
        ],
    };
    const optionElements = dateRangeData[chartType].map((option, index) => (
        <label
            key={option.topHeading}
            htmlFor={`${index}option`}
            className={`block py-1 px-3 hover:bg-tableBackground2 group ${
                chartType !== "Visualizer"
                    ? "pointer-events-none"
                    : "cursor-pointer"
            }`}
        >
            <p className="text-textColor text-xs whitespace-nowrap group-hover:font-bold">
                {option.topHeading}
            </p>
            <p className="text-textColor2 text-[10px] whitespace-nowrap group-hover:text-textColorHover">
                {option.bottomHeading}
            </p>
            <input
                type="radio"
                name="dateRangeDays"
                value={option.value}
                checked={
                    option.value !== "custom"
                        ? parseInt(dateParams) === option.value
                        : dateParams === option.value
                }
                className={`
                hidden`}
                id={`${index}option`}
                onChange={handleDateRangeDays}
                onClick={handleOptionClick}
            />
        </label>
    ));

    return (
        <div className="flex flex-col gap-4 text-sm relative w-[230px]">
            <p className="absolute bottom-[101%] text-base font-bold">
                Date Range
            </p>
            <div
                className={`border border-border p-1 px-4 rounded-md h-min flex items-center justify-between gap-3 w-full ${
                    isLoading ? "pointer-events-none" : "cursor-pointer"
                }`}
                onClick={(e) => toggleDateRange(e)}
            >
                <p className="text-textColor text-sm whitespace-nowrap w-full">
                    {chartType === "Visualizer" &&
                        (dateParams !== "custom"
                            ? dateRangeData[chartType].filter(
                                  (option) =>
                                      parseInt(dateParams) === option.value
                              )[0].topHeading
                            : nonCustomValue
                            ? nonCustomValue
                            : customRange
                            ? `${customRange.start_date} - ${customRange.end_date}`
                            : `Custom`)}
                    {chartType === "Pareto" && "Last 1 Week"}
                    {chartType === "Cycletime" && "Last 15 Months"}
                </p>

                {showDateRange ? <UpwardArrow /> : <DownwardArrow />}
            </div>
            {showDateRange && (
                <div
                    className="border border-border absolute top-[105%] bg-white z-10 rounded-md overflow-hidden w-full"
                    ref={menuRef}
                >
                    {optionElements}
                </div>
            )}
            {showCustomRangeMenu && (
                <DatePickerPopUp
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    handleCancel={handleCancel}
                    handleClick={changeDateRange}
                    includeIntervalsForStartDate={[
                        {
                            start: new Date(dataStartLimit).setDate(
                                new Date(dataStartLimit).getDate() - 1
                            ),
                            end: new Date(dataEndLimit),
                        },
                    ]}
                    includeIntervalsForEndDate={[
                        {
                            start: new Date(startDate),
                            end: new Date(dataEndLimit),
                        },
                    ]}
                    onChangeStartFunction={(date) => {
                        setStartDate(date);
                        if (date > endDate) {
                            setEndDate(date);
                        }
                    }}
                    onChangeEndFunction={(date) => {
                        setEndDate(date);
                    }}
                />
            )}
        </div>
    );
}
