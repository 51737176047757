import { useRef, useEffect, useState, useContext } from "react";
import * as d3 from "d3";
import { ChartsContext } from "../../contexts/ChartsContext";

const useResizeObserver = (ref) => {
    const [dimensions, setDimensions] = useState(null);
    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => setDimensions(entry.contentRect));
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);
    return dimensions;
};

const CycleTimeUptimeChart = () => {
    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);
    const { cycleTimeData: data, createTooltip } = useContext(ChartsContext);

    useEffect(() => {
        const svg = d3.select(svgRef.current);

        if (!dimensions) return;

        const xScale = d3
            .scaleBand()
            .domain(data.map((d) => d.week))
            .range([0, dimensions.width])
            .padding(0.1);

        const yScale = d3
            .scaleLinear()
            .domain([0, d3.max(data, (d) => 45)])
            .range([dimensions.height, 0]);

        const xAxis = d3
            .axisBottom(xScale)
            .tickValues(
                xScale
                    .domain()
                    .filter((value, index) => (index - 2) % 6 === 0 && value)
            );

        const yAxis = d3
            .axisLeft(yScale)
            .ticks(5)
            .tickFormat((d) => `${d}%`)
            .tickSize(-dimensions.width, 0, 0);

        svg.select(".x-axis")
            .attr("transform", `translate(0, ${dimensions.height})`)
            .call(xAxis)
            .selectAll("text")
            .attr("dy", "1em");

        svg.select(".y-axis")
            .call(yAxis)
            .selectAll(".tick text")
            .style("transform", `translate(-8px, 0)`);

        svg.selectAll(".tick line").style("stroke", "#D5D5D5");
        svg.selectAll(".domain").style("stroke", "none");

        const colorScale = d3
            .scaleOrdinal()
            .domain([
                "transfer",
                "wait for acetylator",
                "heatup",
                "vac torque",
                "extrusion",
                "cool",
            ])
            .range([
                "#FDA50F",
                "#C00000",
                "#FFD300",
                "#D6AD00",
                "#FFFF66",
                "#FFBF00",
            ]);

        const stackedData = d3
            .stack()
            .keys([
                "transfer",
                "wait for acetylator",
                "heatup",
                "vac torque",
                "extrusion",
                "cool",
            ])(data);

        const tooltip = createTooltip("tooltip");

        // Two functions that change the tooltip when user hover / leave
        const mouseenterBars = function (event, phase) {
            const subGroup = d3.select(this.parentNode).datum().key;
            tooltip.style("display", "grid");
            const tooltipHeadingClasses = "text-[#7a7a7a]";

            const newHTML = `
                <span class=${tooltipHeadingClasses}>${
                subGroup.charAt(0).toUpperCase() + subGroup.slice(1)
            }: </span><span>${
                phase.data[subGroup]
            }%</span><span class=${tooltipHeadingClasses}>Week: </span><span>${
                phase.data["week"]
            }</span>`;

            tooltip
                .html(newHTML)
                .style("left", d3.pointer(event)[0] + "px")
                .style("top", d3.pointer(event)[1] + "px");
            d3.select(this).style("stroke", "black").style("opacity", 1);
            event.stopPropagation();
        };

        const mouseleaveBars = function (event) {
            tooltip.style("display", "none");
            d3.select(this).style("stroke", "none");
            event.stopPropagation();
        };

        svg.selectAll(".clipRect")

            .attr("width", dimensions.width)
            .attr("height", dimensions.height);

        svg.selectAll(".bars")
            .data(stackedData)
            .join("g")
            .attr("class", "bars")
            .attr("fill", (d) => {
                return colorScale(d.key);
            })
            .selectAll("rect")
            .data((d) => d)
            .join("rect")
            .attr("x", (d) => {
                return xScale(d.data.week);
            })
            .attr("y", (d) => yScale(d[1]))
            .attr("clip-path", "url(#clip)")
            .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
            .attr("width", xScale.bandwidth())
            .on("mouseenter", mouseenterBars)
            .on("mouseleave", mouseleaveBars);

        // Add line chart for target improvement
        const lineImprovement = d3
            .line()
            .x((d) => xScale(d.week) + xScale.bandwidth() / 2)
            .y((d) => yScale(d["target improvement"]));

        svg.selectAll(".pathImprovement")
            .data([data])
            .join("path")
            .attr("class", "pathImprovement")
            .attr("fill", "none")
            .style("stroke-dasharray", "6, 3")
            .attr("stroke", "#00C500")
            .attr("stroke-width", 5)
            .attr("d", lineImprovement);

        // Add line chart for baseline
        const lineBaseline = d3
            .line()
            .x((d) => xScale(d.week) + xScale.bandwidth() / 2)
            .y((d) => yScale(d.baseline));

        svg.selectAll(".pathBaseline")
            .data([data])
            .join("path")
            .attr("class", "pathBaseline")
            .attr("fill", "none")
            .attr("stroke", "#2C2C2C")
            .attr("stroke-width", 3)
            .attr("d", lineBaseline);

        // Add line chart for four-weekmoving average
        const lineMovingAverage = d3
            .line()
            .x((d) => xScale(d.week) + xScale.bandwidth() / 2)
            .y((d) => yScale(d["four-week moving average"]))
            .curve(d3.curveMonotoneX);

        svg.selectAll(".pathMovingAverage")
            .data([data])
            .join("path")
            .attr("class", "pathMovingAverage")
            .style("stroke-dasharray", "2, 2")
            .attr("fill", "none")
            .attr("stroke", "#2C2C2C")
            .attr("stroke-width", 2)
            .attr("d", lineMovingAverage);
    }, [data, dimensions]);

    return (
        <div ref={wrapperRef} className="relative h-96">
            <svg ref={svgRef} className="overflow-visible w-full h-full">
                <g className="x-axis" />
                <g className="y-axis" />
                <defs className="defs">
                    <clipPath id="clip">
                        <rect className="clipRect"></rect>
                    </clipPath>
                </defs>
            </svg>
            <div className="tooltip hidden absolute pointer-events-none w-max grid-cols-[max-content_max-content] gap-x-1">
                Hello
            </div>
        </div>
    );
};

export default CycleTimeUptimeChart;
