import { useContext, useState, useEffect } from "react";
import { ReactComponent as UpwardArrow } from "../assets/insightsIcons/upward-arrow.svg";
import { ReactComponent as DownwardArrow } from "../assets/insightsIcons/downward-arrow.svg";
import { Context } from "../contexts/Context";
import { ChartsContext } from "../contexts/ChartsContext";
import { setPhaseIdle, get_config } from "../services/api-calls";

function AllAssetToggle({ showAllAssetMenu, toggleAllAssetMenu }) {
    const { isLoading, setIsLoading } = useContext(Context);
    const {
        allAssetPhase,
        getVisualizerAndSensorData,
        defaultPhase,
        setAllAssetPhase,
    } = useContext(ChartsContext);
    // const [allAssetPhase, setAllAssetPhase] = useState([...allAssetPhase]);
    const [idlePhaseList, setIdlePhaseList] = useState([]);

    useEffect(() => {
        let newList = allAssetPhase.filter((obj) => obj.isChecked === true);
        let b = newList.map((obj) => {
            let a = obj.process.filter((item) => item.is_idle === true);
            return {
                asset: obj.asset,
                phase: a.map((perObj) => perObj.phase),
            };
        });
        setIdlePhaseList(b);
        // setDefaultIdlePhase();
    }, []);
    const setDefaultIdlePhase = () => {
        const list = defaultPhase.map((obj) => {
            return {
                ...obj,
                process: obj.process.map((itm) => {
                    return { ...itm };
                }),
            };
        });
        // debugger
        setAllAssetPhase([...list]);
    };

    const isAssetChecked = (e, i, assetName) => {
        const assetsRow = allAssetPhase;
        assetsRow[i].isChecked = e.target.checked;

        if (e.target.checked === true) {
            let list = idlePhaseList;
            list.push({ asset: assetName, phase: [] });
            setIdlePhaseList(list);
        } else {
            assetsRow[i].process.map((obj) => {
                obj.is_idle = false;
                return obj;
            });
            let index = idlePhaseList.findIndex(
                (itm) => itm.asset === assetName
            );
            idlePhaseList.splice(index, 1);
            setIdlePhaseList([...idlePhaseList]);
            // debugger
        }
        setAllAssetPhase([...assetsRow]);
        // setAllAssetPhase
    };
    const idlePhaseSelect = (e, assetName, phaseName, i, j) => {
        const phaseRow = [...allAssetPhase];
        const a = defaultPhase;
        phaseRow[i].process[j].is_idle = e.target.checked;
        // debugger
        setAllAssetPhase([...phaseRow]);
        let index = idlePhaseList.findIndex((obj) => obj.asset === assetName);
        let list = idlePhaseList;
        if (e.target.checked === true) {
            list[index]?.phase?.push(phaseName);
            setIdlePhaseList(list);
        } else {
            let phaseIndex = idlePhaseList[index].phase.findIndex(
                (itm) => itm === phaseName
            );
            list[index]?.phase?.splice(phaseIndex, 1);
        }
    };
    const handleCheckAll = (e) => {
        if (e.target.checked === true) {
            let allAsset = allAssetPhase.map((obj) => {
                return {
                    ...obj,
                    isChecked: true,
                    process: obj.process.map((itm) => {
                        return {
                            ...itm,
                            is_idle: true,
                        };
                    }),
                };
            });
            setAllAssetPhase([...allAsset]);
            const idleListAll = allAssetPhase.map((obj) => {
                return {
                    asset: obj.asset,
                    phase: obj.process.map((itm) => {
                        return itm.phase;
                    }),
                };
            });
            setIdlePhaseList([...idleListAll]);
            // setIdlePhaseList(['All']);
        } else {
            let allAsset = allAssetPhase.map((obj) => {
                return {
                    ...obj,
                    isChecked: false,
                    process: obj.process.map((itm) => {
                        return {
                            ...itm,
                            is_idle: false,
                        };
                    }),
                };
            });
            setAllAssetPhase([...allAsset]);
            setIdlePhaseList([]);
        }
    };

    const applyIdlePhase = async () => {
        try {
            setIsLoading(true);
            let response = await setPhaseIdle(idlePhaseList);
            if (response.code === 200) {
                toggleAllAssetMenu(null);
                get_config()
                    .then(async (res) => {
                        const configColor = res.data;
                        getVisualizerAndSensorData(configColor);
                    })
                    .catch((e) => {
                        console.log(e);
                        setIsLoading(false);
                    });
            }
            setIsLoading(true);
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        // <div className="relative flex mr-3">
        <>
            <div
                className={`border border-border  p-1 px-3 rounded-md h-min flex items-center justify-between w-[129px] relative ${
                    isLoading ? "pointer-events-none" : "cursor-pointer"
                } `}
                onClick={(e) => toggleAllAssetMenu(e)}
            >
                <p className="text-textColor text-sm whitespace-nowrap w-full mr-2">
                    Set Idle
                </p>
                {showAllAssetMenu ? <UpwardArrow /> : <DownwardArrow />}
            </div>
            {showAllAssetMenu && (
                <div className="absolute top-[140%]   bg-white z-0 left-0 w-[250px] border space-y-2 rounded-sm">
                    <div className=" my-2 flex border-b p-3">
                        <input
                            name="All"
                            type="checkbox"
                            className="w-4 h-4 accent-[#1C7E41]"
                            onChange={handleCheckAll}
                        />
                        <span className=" text-sm text-textColor ml-3">
                            All
                        </span>
                    </div>
                    <div className="max-h-[60vh] overflow-auto scrollbar-thin scrollbar-track-gray-200">
                        {allAssetPhase?.map((assetData, index) => (
                            <div>
                                <div key={index} className={` flex px-3`}>
                                    <input
                                        name={assetData.asset}
                                        type="checkbox"
                                        checked={assetData.isChecked}
                                        class="w-4 h-4 bg-red-100 border-red-300 text-red-500 focus:ring-red-200 accent-[#1C7E41]"
                                        onChange={(e) =>
                                            isAssetChecked(
                                                e,
                                                index,
                                                assetData.asset
                                            )
                                        }
                                    />
                                    {/* <label for="green-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Green</label> */}
                                    <span className="w-[90%] text-sm ml-3 text-textColor break-all">
                                        {assetData.asset}
                                    </span>
                                </div>
                                {assetData.isChecked === true &&
                                    assetData.process.map((phaseData, j) => (
                                        <div
                                            className={`${
                                                j ===
                                                assetData.process.length - 1
                                                    ? "border-b mb-4 pb-2"
                                                    : ""
                                            }`}
                                        >
                                            <div
                                                key={j}
                                                className=" ml-4 flex px-3 "
                                            >
                                                <input
                                                    name={phaseData.phase}
                                                    type="checkbox"
                                                    className="w-4 h-4 accent-[#1C7E41]"
                                                    checked={phaseData.is_idle}
                                                    onChange={(e) =>
                                                        idlePhaseSelect(
                                                            e,
                                                            assetData.asset,
                                                            phaseData.phase,
                                                            index,
                                                            j
                                                        )
                                                    }
                                                />
                                                <span className="text-textColor text-sm ml-3 break-all">
                                                    {phaseData.phase}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        ))}
                    </div>

                    <div className="flex gap-3 justify-end p-3">
                        <button
                            className="bg-transparent"
                            onClick={(e) => {
                                setDefaultIdlePhase();
                                toggleAllAssetMenu(e);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className={`bg-primary text-white py-1 px-4 rounded-md 
                    }`}
                            onClick={applyIdlePhase}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            )}
        </>
        // </div>
    );
}

export default AllAssetToggle;
