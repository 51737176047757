import { useContext, useState } from "react";
import { ReactComponent as ExpandGreen } from "../../assets/paretoTableIcons/expand-green.svg";
import { ReactComponent as CollapseGreen } from "../../assets/paretoTableIcons/collapse-green.svg";
import { ReactComponent as ExpandBlue } from "../../assets/paretoTableIcons/expand-blue.svg";
import { ReactComponent as CollapseBlue } from "../../assets/paretoTableIcons/collapse-blue.svg";
import { ChartsContext } from "../../contexts/ChartsContext";

function ParetoTable() {
    const { paretoTableData } = useContext(ChartsContext);

    const [showTable, setShowTable] = useState(false);
    const [showPhaseTable, setShowPhaseTable] = useState(false);
    const [showHoursSavedTable, setShowHoursSavedTable] = useState(false);

    function toggleTable() {
        setShowTable((prevShowTable) => !prevShowTable);
        setShowHoursSavedTable(
            (prevShowHoursSavedTable) =>
                prevShowHoursSavedTable && !prevShowHoursSavedTable
        );
        setShowPhaseTable(
            (prevShowPhaseTable) => prevShowPhaseTable && !prevShowPhaseTable
        );
    }
    function togglePhaseTable() {
        setShowPhaseTable((prevShowPhaseTable) => !prevShowPhaseTable);
    }
    function toggleHoursSavedTable() {
        setShowHoursSavedTable(
            (prevShowHoursSavedTable) => !prevShowHoursSavedTable
        );
    }
    function phasesElements(backgroundColor, type) {
        return [
            "Wait for Acet",
            "Transfer",
            "Heat",
            "VacTorque",
            "Extrusion",
            "Cool",
        ].map((item) => (
            <p
                key={item}
                className={`text-sm px-1 ${backgroundColor} ${
                    !showPhaseTable && type === "actual" ? "hidden" : "block"
                } ${
                    !showHoursSavedTable && type === "baseline"
                        ? "hidden"
                        : "block"
                }`}
            >
                {item}
            </p>
        ));
    }

    const tableElements = paretoTableData.map((item, index1) => {
        return [
            "product",
            "n_equals_1",
            "n_greater_1",
            "t_wait_for_acet",
            "t_transfer",
            "t_heat",
            "t_vac_torque",
            "t_extrusion",
            "t_cool",
            "actual_total_time",
            "baseline_total_time",
            "h_wait_for_acet",
            "h_transfer",
            "h_heat",
            "h_vac_torque",
            "h_extrusion",
            "h_cool",
            "hrs_saved_total",
        ].map((entry, index2) => (
            <p
                key={`${index1}${index2}${entry}`}
                className={`place-self-center text-xs px-2 ${
                    entry === "actual_total_time" || entry === "hrs_saved_total"
                        ? "pl-5"
                        : "pl-2"
                } ${borderTopClass(item.product)} ${borderBottomClass(
                    item.product
                )} 
                    ${
                        !showPhaseTable &&
                        (entry === "t_wait_for_acet" ||
                            entry === "t_transfer" ||
                            entry === "t_heat" ||
                            entry === "t_vac_torque" ||
                            entry === "t_extrusion" ||
                            entry === "t_cool")
                            ? "hidden"
                            : "block"
                    }
                    ${
                        !showHoursSavedTable &&
                        (entry === "h_wait_for_acet" ||
                            entry === "h_transfer" ||
                            entry === "h_heat" ||
                            entry === "h_vac_torque" ||
                            entry === "h_extrusion" ||
                            entry === "h_cool")
                            ? "hidden"
                            : "block"
                    }
                `}
            >
                {(item.product === "Washes" || item.product === "Empty") &&
                entry !== "product" &&
                entry !== "n_equal_1" &&
                entry !== "n_greater_1" &&
                entry !== "actual_total_time" &&
                entry !== "baseline_total_time" &&
                entry !== "hrs_saved_total"
                    ? "-"
                    : item[entry]}
            </p>
        ));
    });

    function borderTopClass(product) {
        return product === "Washes" ? "border-t border-t-border" : "";
    }
    function borderBottomClass(product) {
        return product === "Empty" ? "border-b border-b-border" : "";
    }

    return (
        <div className="relative flex col-start-2 col-end-4 ml-7">
            <div
                className="w-5 shrink-0 h-56 rounded-md flex flex-col items-center justify-center shadow-[0px_3px_6px_#23824BBA] relative cursor-pointer"
                onClick={toggleTable}
            >
                {showTable ? (
                    <CollapseGreen className="w-4 absolute top-1" />
                ) : (
                    <ExpandGreen className="w-4 absolute top-1" />
                )}
                <span>T</span>
                <span>A</span>
                <span>B</span>
                <span>L</span>
                <span>E</span>
            </div>

            <div
                className={`text-center pb-1 gap-y-[4.5px] overflow-y-visible ${
                    showTable ? "grid" : "hidden"
                } overflow-x-auto scrollbar ${
                    !showPhaseTable && !showHoursSavedTable
                        ? "grid-cols-[repeat(6,max-content)]"
                        : (!showPhaseTable && showHoursSavedTable) ||
                          (showPhaseTable && !showHoursSavedTable)
                        ? "grid-cols-[repeat(12,max-content)]"
                        : "grid-cols-[repeat(18,max-content)]"
                }`}
            >
                <p className="col-span-3 text-sm font-bold">Polymerizer</p>
                <p
                    className={`col-span-6 text-sm font-bold ${
                        showPhaseTable ? "block" : "hidden"
                    }`}
                >
                    Time by product and phase for current week (09/30/2019)
                </p>
                <div className="text-sm font-bold relative">
                    <div
                        className="absolute left-0 w-5 h-56 rounded-md shadow-[0px_3px_6px_#616161] bg-collapseExpand cursor-pointer overflow-y-visible"
                        onClick={togglePhaseTable}
                    >
                        <div className="relative flex flex-col items-center justify-center h-full">
                            {showPhaseTable ? (
                                <CollapseBlue className="w-4 absolute top-1" />
                            ) : (
                                <ExpandBlue className="w-4 absolute top-1" />
                            )}
                            {!showPhaseTable &&
                                ["E", "X", "P", "A", "N", "D"].map(
                                    (letter, index) => (
                                        <span key={`${index}${letter}`}>
                                            {letter}
                                        </span>
                                    )
                                )}
                            {showPhaseTable &&
                                ["C", "O", "L", "L", "A", "P", "S", "E"].map(
                                    (letter, index) => (
                                        <span key={`${index}${letter}`}>
                                            {letter}
                                        </span>
                                    )
                                )}
                        </div>
                    </div>
                    Actual
                </div>
                <p className="text-sm font-bold">Baseline</p>
                <p
                    className={`col-span-6 text-sm font-bold ${
                        showHoursSavedTable ? "block" : "hidden"
                    }`}
                >
                    Hours saved versus baseline
                </p>
                <div className="text-sm font-bold relative pl-5">
                    <div
                        className="absolute left-0 w-5 h-56 rounded-md shadow-[0px_3px_6px_#616161] bg-collapseExpand cursor-pointer"
                        onClick={toggleHoursSavedTable}
                    >
                        <div className="relative flex flex-col items-center justify-center h-full">
                            {showHoursSavedTable ? (
                                <CollapseBlue className="w-4 absolute top-1" />
                            ) : (
                                <ExpandBlue className="w-4 absolute top-1" />
                            )}
                            {!showHoursSavedTable &&
                                ["E", "X", "P", "A", "N", "D"].map(
                                    (letter, index) => (
                                        <span key={`${index}${letter}`}>
                                            {letter}
                                        </span>
                                    )
                                )}
                            {showHoursSavedTable &&
                                ["C", "O", "L", "L", "A", "P", "S", "E"].map(
                                    (letter, index) => (
                                        <span key={`${index}${letter}`}>
                                            {letter}
                                        </span>
                                    )
                                )}
                        </div>
                    </div>
                    Hrs Saved
                </div>
                {["Product", "n=1", "n>1"].map((item) => (
                    <p key={item} className="text-sm px-2 bg-tableBackground1">
                        {item}
                    </p>
                ))}
                {phasesElements("bg-tableBackground3", "actual")}
                <p className="text-sm px-2 bg-tableBackground1 pl-6">
                    Total Time
                </p>
                <p className="text-sm px-2 bg-tableBackground1">Total Time</p>
                {phasesElements("bg-tableBackground4", "baseline")}
                <p className="text-sm px-2 bg-tableBackground2 pl-6">
                    vs. Baseline
                </p>
                {tableElements}
            </div>
        </div>
    );
}

export default ParetoTable;
