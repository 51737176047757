import { useRef, useEffect, useState, useContext } from "react";
import * as d3 from "d3";
import { ChartsContext } from "../../contexts/ChartsContext";

const useResizeObserver = (ref) => {
    const [dimensions, setDimensions] = useState(null);
    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => setDimensions(entry.contentRect));
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);
    return dimensions;
};

const CycleTimePhaseChart = () => {
    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);
    const { cycleTimeData: data, createTooltip } = useContext(ChartsContext);

    useEffect(() => {
        const svg = d3.select(svgRef.current);

        if (!dimensions) return;

        const xScale = d3
            .scaleBand()
            .domain(data.map((d) => d.week))
            .range([0, dimensions.width])
            .padding(0.1);

        const yScale = d3
            .scaleLinear()
            .domain([0, 50])
            .range([dimensions.height, 0]);

        const xAxis = d3
            .axisBottom(xScale)
            .tickValues(
                xScale
                    .domain()
                    .filter((value, index) => (index - 2) % 6 === 0 && value)
            );
        const yAxis = d3
            .axisLeft(yScale)
            .ticks(5)
            .tickSize(-dimensions.width, 0, 0);

        svg.select(".x-axis")
            .attr("transform", `translate(0, ${dimensions.height})`)
            .call(xAxis)
            .selectAll("text")
            .attr("dy", "1em");

        svg.select(".y-axis")
            .call(yAxis)
            .selectAll(".tick text")
            .style("transform", `translate(-8px, 0)`);

        svg.selectAll(".tick line").style("stroke", "#D5D5D5");
        svg.selectAll(".domain").style("stroke", "none");

        const tooltip = createTooltip("tooltip");

        // Two functions that change the tooltip when user hover / leave
        const mouseenterBars = function (event, phase) {
            tooltip.style("display", "grid");
            const tooltipHeadingClasses = "text-[#7a7a7a]";
            const newHTML = `<span class=${tooltipHeadingClasses}>Duration: </span><span>${phase["phase heatup"]} hrs</span>
                <span class=${tooltipHeadingClasses}>Week: </span><span>${phase["week"]}</span>
                
                `;
            tooltip
                .html(newHTML)
                .style("left", d3.pointer(event)[0] + "px")
                .style("top", d3.pointer(event)[1] + "px");
            d3.select(this).style("stroke", "black").style("opacity", 1);
            event.stopPropagation();
        };

        const mouseleaveBars = function (event) {
            tooltip.style("display", "none");
            d3.select(this).style("stroke", "none");
            event.stopPropagation();
        };

        svg.selectAll(".bar")
            .data(data)
            .join("rect")
            .attr("class", "bar")

            .attr("x", (d) => xScale(d.week))
            .attr("y", (d) => yScale(d["phase heatup"])) // Update the y position to be the percentage value
            .attr("width", (d) => xScale.bandwidth())
            .attr(
                "height",
                (d) => dimensions.height - yScale(d["phase heatup"])
            ) // Update the height to be the percentage value
            .attr("fill", "#FF8282")
            .on("mouseenter", mouseenterBars)
            .on("mouseleave", mouseleaveBars);

        // Add line chart for baseline
        const lineBaseline = d3
            .line()
            .x((d) => xScale(d.week) + xScale.bandwidth() / 2)
            .y((d) => yScale(d["phase baseline loss"]));

        svg.selectAll(".pathBaseline")
            .data([data])
            .join("path")
            .attr("class", "pathBaseline")
            .attr("fill", "none")
            .attr("stroke", "#2C2C2C")
            .attr("stroke-width", 3)
            .attr("d", lineBaseline);

        // Add line chart for four-weekmoving average
        const lineMovingAverage = d3
            .line()
            .x((d) => xScale(d.week) + xScale.bandwidth() / 2)
            .y((d) => yScale(d["phase four-week moving average"]))
            .curve(d3.curveMonotoneX);

        svg.selectAll(".pathMovingAverage")
            .data([data])
            .join("path")
            .attr("class", "pathMovingAverage")
            .style("stroke-dasharray", "2, 2")
            .attr("fill", "none")
            .attr("stroke", "#2C2C2C")
            .attr("stroke-width", 2)
            .attr("d", lineMovingAverage);
    }, [data, dimensions]);

    return (
        <div ref={wrapperRef} className="relative h-96">
            <svg ref={svgRef} className="overflow-visible w-full h-full">
                <g className="x-axis" />
                <g className="y-axis" />
            </svg>
            <div className="tooltip hidden absolute pointer-events-none w-max grid-cols-[max-content_max-content] gap-x-1">
                Hello
            </div>
        </div>
    );
};

export default CycleTimePhaseChart;
