import { useContext } from "react";
import { ReactComponent as SuccessfulTick } from "../assets/uploadFileIcons/correct-tick.svg";
import { ReactComponent as Error } from "../assets/uploadFileIcons/error.svg";
import { Context } from "../contexts/Context";

export default function Popup() {
    const {
        isBaselineAppliedSuccessfully,
        setIsBaselineAppliedSuccessfully,
        setShouldPopupOpen,
    } = useContext(Context);

    return (
        <div className="flex justify-center items-center absolute w-full h-full">
            <div className="w-full h-full absolute bg-gray-700 z-10 opacity-80 flex justify-center items-center"></div>
            <div className="absolute z-20 opacity-100 bg-white shadow-xl rounded-md p-5 px-9 flex flex-col items-center gap-6">
                <div className="flex flex-col justify-center items-center gap-2">
                    {isBaselineAppliedSuccessfully ? (
                        <SuccessfulTick className="h-10 w-10" />
                    ) : (
                        <Error className="h-10 w-10" />
                    )}
                    <h1>
                        {isBaselineAppliedSuccessfully
                            ? "New baseline period is set"
                            : "Baseline period couldn't be applied"}
                    </h1>
                </div>
                <button
                    className={`bg-primary text-white py-1 px-6 rounded-md`}
                    onClick={() => {
                        setShouldPopupOpen(false);
                        setIsBaselineAppliedSuccessfully(false);
                    }}
                >
                    {isBaselineAppliedSuccessfully ? "View" : "Try Again"}
                </button>
            </div>
        </div>
    );
}
