import axios from "axios";

// const baseURL = "https://cw-api.emblemtechnologies.com";
const baseURL = "https://cw-api-consultant.emblemtechnologies.com/";

// const baseURL = "http://192.168.16.138:8001";
const instance = axios.create({
    baseURL: baseURL,
});

async function login(user) {
    const response = await axios.post(`${baseURL}/client/v1/auth/login/`, user);
    return response.data;
}
instance.interceptors.request.use(
    (configHeader) => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            configHeader.headers["Authorization"] = token;
        }
        return configHeader;
    },
    (error) => {
        Promise.reject(error);
    }
);

async function get_cycleTime() {
    const response = await instance.get(
        "client/v1/data/get_cycle_time_chart_data"
    );
    return response.data;
}

async function get_pareto() {
    const response = await instance.get("client/v1/data/get_pareto_chart");
    return response.data;
}

async function get_asset_sensor() {
    const response = await instance.get("client/v2/data/asset_sensor/");

    return response.data;
}

async function get_visualizer(params) {
    const config = { params };
    const response = await instance.get(
        "client/v2/data/get_visualizer",
        config
    );
    return response.data;
}

async function get_sensor(params) {
    const sensorStartEndDate = { params };
    const response = await instance.get(
        "client/v2/data/get_sensor",
        sensorStartEndDate
    );
    return response.data;
}

async function get_config() {
    const response = await instance.get("client/v2/config");
    return response.data;
}

async function set_baseline(baselinePeriod) {
    const response = await instance.post(
        "client/v2/data/set_baseline/",
        baselinePeriod
    );
    return response.data;
}

async function check_file_format_phase(file) {
    const response = await instance.post(
        "client/v2/data/check_file_format/",
        file
    );
    return response.data;
}

async function check_file_format_sensor(file) {
    const response = await instance.post(
        "client/v2/data/check_sensor_file_format/",
        file
    );
    return response.data;
}

async function save_file_phase(file) {
    const response = await instance.post("client/v2/data/upload_file/", file);
    return response.data;
}

async function save_file_sensor(file) {
    const response = await instance.post(
        "client/v2/data/upload_file_sensor/",
        file
    );
    return response.data;
}

async function check_file_progress() {
    const response = await instance.get("client/v2/data/check_file_progress");
    return response.data;
}

async function assign_sensor(assetObject) {
    const response = await instance.put(
        "client/v2/data/asset_sensor/",
        assetObject
    );
    return response.data;
}
async function get_file_list(search) {
    const response = await instance.get(
        `client/v2/data/uploaded_files?search=${search}`
    );
    return response.data;
}
async function activateFile(file) {
    const response = await instance.post("/client/v2/data/activate_file", file);
    return response.data;
}
async function setPhaseIdle(idlePhaseList) {
    const response = await instance.post("/client/v2/data/set_phase_idle", {
        idle_phases: idlePhaseList,
    });
    return response.data;
}
async function productPhase() {
    const response = await instance.get("client/v2/data/filter_List");
    return response.data;
}

async function get_golden_time(params) {
    const page = { params };
    const response = await instance.get(
        "client/v2/data/golden_cycle_time",
        page
    );
    return response.data;
}

export {
    login,
    get_cycleTime,
    get_pareto,
    get_visualizer,
    get_config,
    set_baseline,
    check_file_format_phase,
    check_file_format_sensor,
    save_file_phase,
    save_file_sensor,
    check_file_progress,
    get_asset_sensor,
    assign_sensor,
    get_file_list,
    activateFile,
    get_sensor,
    setPhaseIdle,
    productPhase,
    get_golden_time,
};
