import { useContext, useEffect, useState, forwardRef, useRef } from "react";
import DatePicker from "react-datepicker";
import { ReactComponent as CalendarIcon } from "../assets/baselineIcons/calendar.svg";
import { ChartsContext } from "../contexts/ChartsContext";
import { Context } from "../contexts/Context";
import {
    set_baseline,
    get_visualizer,
    get_config,
} from "../services/api-calls";
import DatePickerPopUp from "./DatePickerPopUp";

// const CustomInput = forwardRef(
//     (
//         {
//             value,
//             onClick,
//             onKeyDown,
//             onKeyUp,
//             onKeyDownCapture,
//             onKeyUpCapture,
//             onChange,
//             onInput,
//             onFocus,
//             onBlur,
//             id,
//             inputName,
//         },
//         ref
//     ) => {
//         return (
//             <label
//                 htmlFor={id}
//                 className="flex justify-between flex-nowrap gap-2 items-center border border-textColor2 p-2 rounded-md w-[124px] relative hover:border-primary active:border-primary focus-within:border-primary group mr-2 cursor-pointer"
//             >
//                 <p className="absolute text-xs text-textColor bottom-full translate-y-1/2 bg-white pr-[2px] border-l-2 border-l-white group-hover:text-primary group-active:text-primary group-focus-within:text-primary">
//                     {inputName}
//                 </p>
//                 <input
//                     type="text"
//                     className="date-input outline-none"
//                     id={id}
//                     ref={ref}
//                     value={value}
//                     onChange={onChange}
//                     onInput={onInput}
//                     onClick={onClick}
//                     onKeyDown={onKeyDown}
//                     onKeyUp={onKeyUp}
//                     onFocus={onFocus}
//                     onBlur={onBlur}
//                     onKeyUpCapture={onKeyUpCapture}
//                     onKeyDownCapture={onKeyDownCapture}
//                     style={{ width: "100%" }}
//                 />
//                 <CalendarIcon className="group-hover:fill-primary group-focus-within:fill-primary" />
//             </label>
//         );
//     }
// );

function BaselineDatePicker({
    toggleBaselineDateRange,
    setShowBaselineDateRange,
}) {
    // const refCustomInput = useRef();
    const {
        baselineStartDate,
        baselineEndDate,

        dataStartLimit,
        dataEndLimit,
        getVisualizerAndSensorData,
    } = useContext(ChartsContext);
    const {
        setIsLoading,
        isBaselineApplied,
        setIsBaselineApplied,
        setIsBaselineAppliedSuccessfully,
        setShouldPopupOpen,
        setConfig,
        setIsThereError,
    } = useContext(Context);
    const [startDate, setStartDate] = useState(baselineStartDate);
    const [endDate, setEndDate] = useState(baselineEndDate);

    useEffect(() => {
        const applyBaselinePeriod = async () => {
            try {
                // debugger;
                if (isBaselineApplied) {
                    setIsLoading(true);
                    setShouldPopupOpen(true);
                    setShowBaselineDateRange(false);
                    const startEndDateObject = {
                        baseline_start_date: startDate.toLocaleString("fr-CA", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        }),
                        baseline_end_date: endDate.toLocaleString("fr-CA", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        }),
                    };
                    const response = await set_baseline(startEndDateObject);
                    if (response.code === 200) {
                        setIsBaselineApplied(false);
                        setIsBaselineAppliedSuccessfully(true);

                        get_config()
                            .then(async (res) => {
                                localStorage.setItem(
                                    "configData",
                                    JSON.stringify(res.data)
                                );

                                const configColor = res.data;
                                setConfig(res.data);

                                getVisualizerAndSensorData(configColor);
                            })
                            .catch((e) => {
                                console.log(e);
                                setIsLoading(false);
                                setIsThereError(true);
                            });
                    }
                }
            } catch (e) {
                console.log(e);
                setIsBaselineApplied(false);
                setIsLoading(false);
                setShouldPopupOpen(true);
            }
        };
        applyBaselinePeriod();
    }, [isBaselineApplied]);

    function changeBaselinePeriod() {
        setIsBaselineApplied(true);
    }
    if (!baselineStartDate) return;

    return (
        <DatePickerPopUp
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleCancel={toggleBaselineDateRange}
            handleClick={changeBaselinePeriod}
            includeIntervalsForStartDate={[
                {
                    start: new Date(dataStartLimit).setDate(
                        new Date(dataStartLimit).getDate() - 1
                    ),
                    end: new Date(dataEndLimit),
                },
            ]}
            includeIntervalsForEndDate={[
                {
                    start: new Date(startDate).setDate(
                        new Date(startDate).getDate() - 1
                    ),
                    end: new Date(dataEndLimit),
                },
            ]}
            onChangeStartFunction={(date) => {
                setStartDate(date);
                if (date > endDate) {
                    setEndDate(date);
                }
            }}
            onChangeEndFunction={(date) => {
                setEndDate(date);
            }}
        />
    );
}

export default BaselineDatePicker;
