import { forwardRef, useContext } from "react";
import { ReactComponent as CalendarIcon } from "../assets/baselineIcons/calendar.svg";
import { ChartsContext } from "../contexts/ChartsContext";
import DatePicker from "react-datepicker";

const CustomInput = forwardRef(
    (
        {
            value,
            onClick,
            onKeyDown,
            onKeyUp,
            onKeyDownCapture,
            onKeyUpCapture,
            onChange,
            onInput,
            onFocus,
            onBlur,
            id,
            inputName,
        },
        ref
    ) => {
        return (
            <label
                htmlFor={id}
                className="flex justify-between flex-nowrap gap-2 items-center border border-textColor2 p-2 rounded-md w-[124px] relative hover:border-primary active:border-primary focus-within:border-primary group mr-2 cursor-pointer"
            >
                <p className="absolute text-xs text-textColor bottom-full translate-y-1/2 bg-white pr-[2px] border-l-2 border-l-white group-hover:text-primary group-active:text-primary group-focus-within:text-primary">
                    {inputName}
                </p>
                <input
                    type="text"
                    className="date-input outline-none"
                    id={id}
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onInput={onInput}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyUpCapture={onKeyUpCapture}
                    onKeyDownCapture={onKeyDownCapture}
                    style={{ width: "100%" }}
                />
                <CalendarIcon className="group-hover:fill-primary group-focus-within:fill-primary" />
            </label>
        );
    }
);

function DatePickerPopUp({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    handleCancel,
    handleClick,
    includeIntervalsForStartDate,
    includeIntervalsForEndDate,
    onChangeStartFunction,
    onChangeEndFunction,
}) {
    const { dataStartLimit, dataEndLimit } = useContext(ChartsContext);
    return (
        <div className="p-4 space-y-4 border border-border absolute top-12 z-20 bg-white text-sm rounded-md">
            <div className="flex">
                {[
                    {
                        customInput: (
                            <CustomInput
                                id="start-date"
                                inputName="Start Date"
                            />
                        ),
                        selected: startDate,
                        // onChangeFunction: (date) => {
                        //     setStartDate(date);
                        //     if (date > endDate) {
                        //         setEndDate(date);
                        //     }
                        // },
                        onChangeFunction: onChangeStartFunction,
                        includeDateIntervals: includeIntervalsForStartDate,
                        selectsStart: true,
                        selectsEnd: false,
                        itemId: 0,
                    },
                    {
                        customInput: (
                            <CustomInput id="end-date" inputName="End Date" />
                        ),
                        selected: startDate >= endDate ? startDate : endDate,
                        // onChangeFunction: (date) => {
                        //     setEndDate(date);
                        // },
                        onChangeFunction: onChangeEndFunction,
                        includeDateIntervals: includeIntervalsForEndDate,
                        selectsEnd: true,
                        selectsStart: false,
                        itemId: 1,
                    },
                ].map((item) => (
                    <DatePicker
                        key={item.itemId}
                        customInput={item.customInput}
                        selected={item.selected}
                        onChange={item.onChangeFunction}
                        includeDateIntervals={item.includeDateIntervals}
                        selectsStart={item.selectsStart}
                        selectsEnd={item.selectsEnd}
                    />
                ))}
            </div>
            <p className="text-xs">*Please enter dates in MM/DD/YYYY format</p>

            <div className="flex gap-3 justify-end">
                <button className="bg-transparent" onClick={handleCancel}>
                    Cancel
                </button>
                <button
                    className={`bg-primary text-white py-2 px-6 rounded-md ${
                        !startDate || !endDate
                            ? "pointer-events-none bg-textColor"
                            : ""
                    }`}
                    onClick={handleClick}
                >
                    Apply
                </button>
            </div>
        </div>
    );
}

export default DatePickerPopUp;
