function ToggleButtons({ firstOption, secondOption, handleClick }) {
    const toggleButtonsElements = [firstOption, secondOption].map(
        (option, index) => (
            <button
                key={option.text}
                className={`border border-border p-1 px-4 flex-1 whitespace-nowrap ${
                    option.view
                        ? "bg-primary text-white"
                        : "bg-white text-textColor"
                } ${index === 0 ? "rounded-l-md" : "rounded-r-md"}`}
                onClick={handleClick}
                disabled={option.view}
            >
                {option.text}
            </button>
        )
    );
    return <div className="flex w-56 text-sm">{toggleButtonsElements}</div>;
}

export default ToggleButtons;
