import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import LoadingScreen from "../../components/loadingComponents/LoadingScreen";
import Popup from "../../components/Popup";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { Context } from "../../contexts/Context";
import ErrorCom from "../../components/errorCom";

export default function Dashboard() {
    const { currentAccessToken, isLoading, shouldPopupOpen, isThereError } =
        useContext(Context);

    if (!currentAccessToken) {
        return <Navigate to="/" replace={true} />;
    }
    return (
        <>
            {isLoading && <LoadingScreen />}
            {!isLoading && shouldPopupOpen && <Popup />}
            <div
                className={`${
                    isLoading && "cursor-wait h-screen overflow-y-hidden"
                } ${shouldPopupOpen && "h-screen overflow-y-hidden"}`}
            >
                <Topbar />
                <Sidebar />
                {isThereError ? <ErrorCom /> : <Outlet />}
            </div>
        </>
    );
}
