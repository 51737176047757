import React from "react";
import { useContext, useEffect, useRef, useState } from "react";

import { Context } from "../contexts/Context";
import { ReactComponent as Cross } from "../assets/insightsIcons/cancel-cross.svg";
import { ReactComponent as CorrectTick } from "../assets/uploadFileIcons/correct-tick.svg";

export default function RefreshModal(props) {
    const { isFileActivationProcess, setFileActivationProcess } =
        useContext(Context);

    return (
        <div className="flex justify-center items-center fixed top-10 left-10 w-full h-full">
            <div className="w-full h-full absolute bg-gray-400 z-10 opacity-80 flex justify-center items-center"></div>
            <div className="absolute z-20 opacity-100 bg-white shadow-xl rounded-md p-5 px-8 flex flex-col items-center max-h-[80vh]">
                <div className="flex justify-end w-full">
                    <Cross
                        className=" w-4 h-4 cursor-pointer"
                        onClick={() => {
                            setFileActivationProcess(false);
                        }}
                    />
                </div>

                <div className="flex flex-col justify-center items-center">
                    <CorrectTick className=" w-16 h-16" />
                    <span className=" text-lg my-4 text-center pt-3">
                        File processing is done. Please refresh.
                    </span>
                    {/* <p className=' text-green-600 pb-4'>{selectedFile.original_file_name}</p> */}
                    <div className="flex justify-center">
                        {/* <button className=' shadow-lg p-1 px-10 rounded-md ml-4 h-8' onClick={()=>{setActivateModal(false)}} >No</button> */}
                        <button
                            className="bg-green-700 mt-4 text-white p-1 px-10 rounded-md h-8 shadow-md"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Refresh
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
