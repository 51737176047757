export default function LegendsTypeContainer(props) {
    return (
        <div className="space-y-4">
            <p className="text-base">{props.heading}</p>
            <div className="grid grid-flow-col grid-rows-4 gap-y-2 gap-x-8 auto-cols-max">
                {props.children}
            </div>
        </div>
    );
}
