import React from "react";
import { ReactComponent as ResetIcon } from "../assets/filterIcons/reset-icon.svg";
import { ReactComponent as SearchIcon } from "../assets/filterIcons/search-icon.svg";

const FiltersComponent = ({
    users,
    filteredUsers,
    searchValue,
    isSearchActive,
    handleSearchChange,
    handleReset,
    handleChange,
    handleSelectAll,
    id,
    searchPlaceHolder,
    Title,
    clicked,
}) => {
    return (
        <div className="flex flex-col w-full">
            <div className="mr-4 ml-4">
                <div className="flex justify-between mt-3">
                    <p className="text-md font-bold">{Title}</p>
                    <button onClick={handleReset}>
                        {clicked ? <ResetIcon /> : <></>}
                    </button>
                </div>
                <div className="mt-[6px]">
                    <p className="text-[11px] text-[#898989] w-[33px] h-[14px]">
                        Search
                    </p>
                    <div className="flex flex-col sm:flex-row items-center relative">
                        <input
                            className="w-full sm:w-96 h-[34px] border border-[#DEDEDE] rounded-md px-3 mb-3 sm:mb-0 sm:mr-3 text-Medium pl-[36px]"
                            type="text"
                            placeholder={searchPlaceHolder}
                            value={searchValue}
                            onChange={handleSearchChange}
                        />
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <SearchIcon className="h-4 w-4 text-gray-500" />
                        </div>
                    </div>
                </div>
                {!isSearchActive && (
                    <label className="Medium flex mt-4">
                        <input
                            type="checkbox"
                            name="allSelect"
                            className="w-[15px] h-[15px] mt-[12px] accent-[#1C7E41]"
                            checked={
                                users.length > 0 &&
                                users.every((user) => user.isChecked)
                            }
                            onChange={handleSelectAll}
                        />
                        <h1 className="w-[19px] h-[23px] mt-[13px] text-[16px] leading-3 text-[#616161] pl-[9px]">
                            All
                        </h1>
                    </label>
                )}
                <div className="relative mt-4">
                    <div className="h-px bg-gray-300"></div>
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="w-full h-px bg-gray-300"></div>
                    </div>
                </div>
                <div className="mt-4 grid grid-cols-2 gap-4 h-[225px] overflow-auto scrollbar-thin scrollbar-track-gray-100">
                    {filteredUsers.map((user, index) => (
                        <div className="form-check" key={index}>
                            <input
                                type="checkbox"
                                className="w-4 h-4 accent-[#1C7E41]"
                                name={user.name}
                                checked={user.isChecked}
                                onChange={(e) => {
                                    const { name, checked } = e.target;
                                    handleChange(name, checked);
                                }}
                                id={id}
                            />
                            <label className="w-[19px] h-[23px] text-[16px] leading-3 text-[#616161] pl-[9px]">
                                {user.name}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className="relative mt-4">
                <div className="h-px bg-gray-300"></div>
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="w-full h-px bg-gray-300"></div>
                </div>
            </div> */}
        </div>
    );
};

export default FiltersComponent;
