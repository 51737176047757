import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as ProfileIcon } from "../../assets/profile-picture.svg";
import { Context } from "../../contexts/Context";

function Topbar() {
    // const [showFileProgressMessage, setFileProgressMessage] = useState(false);
    const { currentUserName, showFileProgressMessage } = useContext(Context);
    const location = useLocation();

    let heading = "";
    if (location.pathname === "/dashboard/visualizer") {
        heading = "Process Visualizer";
    } else if (location.pathname === "/dashboard/process-flow") {
        heading = "Process Flow";
    } else if (location.pathname === "/dashboard/pareto") {
        heading = "Pareto and Baseline Comparison";
    } else if (location.pathname === "/dashboard/cycle-time") {
        heading = "Cycle Time";
    } else if (location.pathname === "/dashboard/upload") {
        heading = "View Files";
    }
    const { logout, isLoading } = useContext(Context);
    return (
        <div
            className={`flex justify-between items-center pl-28 h-16 w-full pr-14 border-b fixed bg-white z-20 ${
                isLoading && "cursor-wait"
            }`}
        >
            <h2 className={`font-bold text-xl`}>{heading}</h2>
            {showFileProgressMessage && (
                <p className="bg-red-300 p-2 rounded-md">
                    File is processing in the background. Please wait.
                </p>
            )}
            <nav className="flex items-center justify-end gap-3 ">
                <p>Hi, {currentUserName}</p>
                <ul className="flex items-center gap-6">
                    <li>
                        <ProfileIcon className="w-7 h-7" />
                    </li>
                    <li
                        className={`font-bold cursor-pointer ${
                            isLoading && "pointer-events-none"
                        }`}
                        onClick={logout}
                    >
                        Logout
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Topbar;
