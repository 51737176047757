import { useContext, useRef, useState, useEffect } from "react";
import * as d3 from "d3";
import { ChartsContext } from "../../contexts/ChartsContext";

const useResizeObserver = (ref) => {
    const [dimensions, setDimensions] = useState(null);
    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => setDimensions(entry.contentRect));
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);
    return dimensions;
};

const BaselineComparisonChart = () => {
    const { paretoData, createTooltip } = useContext(ChartsContext);

    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);
    useEffect(() => {
        const svg = d3.select(svgRef.current);

        const parsedData = paretoData.map((d) => ({
            name: d.name,
            values: [
                { type: "baselineLossTime", value: d.baselineLossTime },
                { type: "actualLossTime", value: d.actualLossTime },
            ],
        }));
        if (!dimensions) return;

        const yScale = d3
            .scaleBand()
            .domain(parsedData.map((d) => d.name))
            .range([0, dimensions.height])
            .padding(0.2);

        const xScale = d3
            .scaleLinear()
            .domain([
                0,
                d3.max(parsedData, (d) => d3.max(d.values, (v) => v.value)),
            ])
            .range([0, dimensions.width]);

        const xAxis = d3
            .axisBottom(xScale)
            .ticks(25)
            .tickFormat(
                (x) =>
                    `${
                        Math.floor(x / 60).toString().length < 2
                            ? "0" + Math.floor(x / 60)
                            : Math.floor(x / 60)
                    }:${
                        (x % 60).toString().length < 2 ? "0" + (x % 60) : x % 60
                    }`
            )
            .tickSize(-dimensions.height);

        const yAxis = d3.axisLeft(yScale).tickSize(-dimensions.width);
        svg.select(".x-axis")
            .attr("transform", `translate(0, ${dimensions.height})`)
            .call(xAxis)
            .selectAll(".tick text")
            .style("transform", `translate(0, 8px)`);

        svg.select(".y-axis")
            .call(yAxis)
            .selectAll(".tick text")
            .style("transform", `translate(-8px, 0)`);

        svg.selectAll(".tick line").style("stroke", "#D5D5D5");
        svg.selectAll(".domain").style("stroke", "#D5D5D5");

        const tooltip = createTooltip();

        // Two functions that change the tooltip when user hover / leave
        const mouseenter = function (event, lostTime) {
            tooltip.style("display", "block");
            const newHTML = [
                `<div>
                ${
                    Math.floor(lostTime.value / 60).toString().length < 2
                        ? "0" + Math.floor(lostTime.value / 60)
                        : Math.floor(lostTime.value / 60)
                } hrs ${
                    (lostTime.value % 60).toString().length < 2
                        ? "0" + (lostTime.value % 60)
                        : lostTime.value % 60
                } mins
                </div>`,
            ];
            tooltip
                .html(newHTML)
                .style("left", `${d3.pointer(event)[0]}px`)

                .style("top", `${event.layerY}px`);
            d3.select(this).style("stroke", "black").style("opacity", 1);
            event.stopPropagation();
        };

        const mouseleave = function (event) {
            tooltip.style("display", "none");
            d3.select(this).style("stroke", "none");
            event.stopPropagation();
        };

        svg.selectAll(".bar")
            .data(parsedData)
            .join("g")
            .attr("class", "bar")
            .attr("transform", (d) => `translate(0, ${yScale(d.name)})`)
            .selectAll("rect")
            .data((d) => d.values)
            .join("rect")
            .attr("width", (d) => xScale(d.value))
            .attr("height", yScale.bandwidth() / 2)
            .attr("x", xScale(0))
            .attr("y", (d, i) => (i === 0 ? 0 : yScale.bandwidth() / 2))
            .style("fill", (d, i) => (i === 0 ? "#B9B9B9" : "#FF8282"))
            .on("mouseenter", mouseenter)
            .on("mouseleave", mouseleave);
    }, [paretoData, dimensions]);

    return (
        <div ref={wrapperRef} className="relative h-96">
            <svg ref={svgRef} className="overflow-visible w-full h-full">
                <g className="x-axis" />
                <g className="y-axis" />
            </svg>
            <div className="tooltip hidden absolute pointer-events-none w-max">
                Hello
            </div>
        </div>
    );
};

export default BaselineComparisonChart;
