import { createContext, useState, useEffect, useContext } from "react";
import {
    get_visualizer,
    get_pareto,
    get_cycleTime,
    get_config,
    get_asset_sensor,
    get_sensor,
} from "../services/api-calls";
import { useLocation } from "react-router-dom";
import { Context } from "./Context";
import * as d3 from "d3";
const ChartsContext = createContext();

function ChartsContextProvider(props) {
    const location = useLocation();
    const {
        setIsLoading,
        isLoading,
        setIsThereError,
        setConfig,
        setShouldPopupOpen,
    } = useContext(Context);
    const [processFlowData, setProcessFlowData] = useState(null);
    const [visualizerData, setVisualizerData] = useState(null);
    const [assetList, setAssetList] = useState(null);
    const [sensorList, setSensorList] = useState(null);
    const [assetSensorList, setAssetSensorList] = useState(null);
    const [currentRangeSensorList, setCurrentRangeSensorList] = useState(null);
    const [sensorData, setSensorData] = useState(null);
    const [sensorsColors, setSensorsColors] = useState(null);
    const [paretoData, setParetoData] = useState(null);
    const [paretoTableData, setParetoTableData] = useState(null);
    const [cycleTimeData, setCycleTimeData] = useState(null);
    const [cycleTimeUptimeView, setCycleTimeUptimeView] = useState(true);
    const [cycleTimePhaseView, setCycleTimePhaseView] = useState(false);
    const [showInsights, setShowInsights] = useState(false);
    const [showDateRange, setShowDateRange] = useState(false);
    // const [dateParams, setDateParams] = useState(5);
    const [graphStartDate, setGraphStartDate] = useState(null);
    const [graphEndDate, setGraphEndDate] = useState(null);
    const [customRange, setCustomRange] = useState(null);
    const [baselineStartDate, setBaselineStartDate] = useState(null);
    const [baselineEndDate, setBaselineEndDate] = useState(null);
    const [dataStartLimit, setDataStartLimit] = useState(null);
    const [dataEndLimit, setDataEndLimit] = useState(null);
    const [isSensorShowing, setIsSensorShowing] = useState(false);
    const [isSensorShowingOnAsset, setIsSensorShowingOnAsset] = useState({});
    const [isDateChange, setIsDateChange] = useState(false);
    const [isFileActive, setIsFileActive] = useState(false);
    const [isPhaseMax, setIsPhaseMax] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [phaseData, setPhaseData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [visualizerNewData, setVisualizerNewData] = useState({});
    const [filteredValues, setFilteredValues] = useState(false);

    const [endDate, setEndDate] = useState(null);
    const [allAssetPhase, setAllAssetPhase] = useState(null);
    const [defaultPhase, setDefaultPhase] = useState(null);

    useEffect(() => {
        const newDataObject = {
            ...customRange,
            product: JSON.stringify(productData),
            phase: JSON.stringify(phaseData),
        };

        setVisualizerNewData(newDataObject);
        setFilteredValues(true);
    }, [customRange, productData, phaseData]);

    async function getVisualizerAndSensorData(configColor) {
        try {
            const response = await get_visualizer(visualizerNewData);
            // setVisualizerData(null);
            // setSensorData(null);
            // const response = await get_visualizer(customRange);

            setIsLoading(false);
            const resData = response.data.assets_list.map((asset) => ({
                asset: asset,
                isChecked: response.data.visualizer_data
                    .filter((item) => item.asset === asset)
                    .some((obj) => obj.is_idle === true),
                process: response.data.visualizer_data
                    .filter((item) => item.asset === asset)
                    .map((item) => ({
                        ...item,
                        phase_start_time: Date.parse(item.phase_start_time),
                        phase_end_time: Date.parse(item.phase_end_time),
                        color:
                            item.is_idle == false
                                ? configColor.colours_data.find(
                                      (phaseColor) =>
                                          phaseColor.name === item.phase
                                  ).color
                                : "#808080",
                        phase_golden_duration: efficientlyGetGoldenDuration(
                            response.data.golden_cycle_data,
                            item
                        ),

                        phase_golden_cycle_end_time: getStartTimeForLostTimeBar(
                            response.data.golden_cycle_data,
                            item
                        ),
                    })),
                batch_process: response.data.visualizer_data
                    .filter((item) => item.asset === asset)
                    .map((item) => item.batch)
                    .filter(
                        (item, index, selfArray) =>
                            selfArray.indexOf(item) === index
                    )
                    .map((batch, index) => ({
                        batch: batch,
                        color: index % 2 === 0 ? "#CACACA" : "#9F9F9F",
                        batch_start_time: Date.parse(
                            response.data.visualizer_data.filter(
                                (item) =>
                                    item.asset === asset && item.batch === batch
                            )[0].phase_start_time
                        ),
                        batch_end_time: Date.parse(
                            response.data.visualizer_data.filter(
                                (item) =>
                                    item.asset === asset && item.batch === batch
                            )[
                                response.data.visualizer_data.filter(
                                    (item) =>
                                        item.asset === asset &&
                                        item.batch === batch
                                ).length - 1
                            ].phase_end_time
                        ),
                    })),
            }));
            setVisualizerData(resData);

            const uniqueList = resData.map((data) => {
                let reduceData = data.process.reduce((accumulator, item) => {
                    const found = accumulator.find(
                        (obj) => obj.phase === item.phase
                    );
                    if (!found && item.phase.toLowerCase() !== "idle") {
                        accumulator.push({ ...item });
                    }
                    return accumulator;
                }, []);
                return { ...data, process: reduceData };
            });
            const newList = resData.map((data) => {
                let reduceData = data.process.reduce((accumulator, item) => {
                    const found = accumulator.find(
                        (obj) => obj.phase === item.phase
                    );
                    if (!found) {
                        accumulator.push({ ...item });
                    }
                    return accumulator;
                }, []);
                return { ...data, process: reduceData };
            });
            setAllAssetPhase([...uniqueList]);
            setDefaultPhase([...newList]);
            setBaselineStartDate(new Date(response.data.baseline_start_date));
            setBaselineEndDate(new Date(response.data.baseline_end_date));
            setDataStartLimit(new Date(response.data.min_start_date));

            setDataEndLimit(new Date(response.data.max_end_date));
            setIsSensorShowingOnAsset(
                response.data.assets_list.reduce(
                    (assetObject, value) => ({
                        ...assetObject,
                        [value]: false,
                    }),
                    {}
                )
            );
            setStartDate(
                customRange
                    ? new Date(customRange.start_date)
                    : new Date(
                          new Date(response.data.min_start_date).setHours(
                              0,
                              0,
                              0
                          )
                      )
            );
            setEndDate(
                customRange
                    ? new Date(customRange.end_date)
                    : new Date(
                          new Date(response.data.min_start_date).setDate(
                              new Date(response.data.min_start_date).getDate() +
                                  4
                          )
                      )
            );
            const sensorResponse = await get_sensor(customRange);

            setCurrentRangeSensorList(sensorResponse.data.sensors_list);
            setSensorData(
                sensorResponse.data.sensors_list.map((sensor) => ({
                    sensor_name: sensor,
                    sensor_data: sensorResponse.data.sensor_data.filter(
                        (sensor_data_item, index) =>
                            sensor_data_item.sensor_name === sensor &&
                            index % 12 === 0
                    ),
                }))
            );
            setFilteredValues(false);
            setIsDateChange(false);
            setIsFileActive(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setIsDateChange(false);
            setIsFileActive(false);
            setIsThereError(true);
            setIsFileActive(true);
        }
    }

    useEffect(() => {
        async function loadProcessFlowData() {
            try {
                setShouldPopupOpen(false);
                setIsThereError(false);
                setProcessFlowData(null);
                setIsLoading(true);
                const response = await get_asset_sensor();
                if (!ignore) {
                    setProcessFlowData(response.data.asset_sensor_data);
                    setSensorList(response.data.sensors_list);
                    setAssetList(
                        response.data.assets_list.map((asset) => ({
                            name: asset,
                            showSettingsMenu: false,
                            showSensorsList: false,
                        }))
                    );

                    setIsLoading(false);
                }
            } catch (e) {
                console.log(e);
                setIsLoading(false);
                setIsThereError(true);
            }
        }
        async function loadVisualizerData() {
            setShouldPopupOpen(false);
            setIsThereError(false);
            async function loadAssetSensorLink() {
                try {
                    setAssetSensorList(null);
                    setIsLoading(true);
                    const response = await get_asset_sensor();
                    if (!ignore) {
                        setAssetSensorList(
                            response.data.assets_list.reduce(
                                (assetObject, value) => ({
                                    ...assetObject,
                                    [value]: response.data.asset_sensor_data
                                        .filter(
                                            (item) =>
                                                item.asset === value &&
                                                item.is_assigned
                                        )
                                        .map((item) => item.sensor),
                                }),
                                {}
                            )
                        );
                        setIsLoading(false);
                        setSensorsColors(response.data.colours_data);
                    }
                } catch (e) {
                    console.log(e);
                    setIsLoading(false);
                    setIsDateChange(false);
                    setIsThereError(true);
                }
            }
            await loadAssetSensorLink();
            if (
                !visualizerData ||
                isDateChange === true ||
                isFileActive === true ||
                filteredValues === true
            ) {
                setIsLoading(true);
                get_config()
                    .then(async (res) => {
                        localStorage.setItem(
                            "configData",
                            JSON.stringify(res.data)
                        );

                        const configColor = res.data;
                        setConfig(res.data);

                        getVisualizerAndSensorData(configColor);
                    })
                    .catch((e) => {
                        console.log(e);
                        setIsLoading(false);
                        setIsDateChange(false);
                        setIsThereError(true);
                    });
            }
        }
        async function loadParetoData() {
            setShouldPopupOpen(false);
            try {
                setIsThereError(false);
                setParetoData(null);
                setIsLoading(true);

                const response = await get_pareto();
                if (!ignore) {
                    setParetoData(
                        response.data.polymerizer_pdata.polymerizer_baseline_pdata
                            .filter((data) => data.phase !== "OPT")
                            .map((data) => ({
                                name: data.label,
                                actualLossTime: getMinutesInteger(
                                    data.week_time
                                ),
                                baselineLossTime: getMinutesInteger(
                                    data.baseline_time
                                ),
                            }))
                    );
                    setParetoTableData(
                        response.data.polymerizer_pdata.polymerizer_pdata
                    );
                }
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setIsLoading(false);
                setIsThereError(true);
            }
        }
        async function loadCycleTimeData() {
            setShouldPopupOpen(false);
            try {
                setIsThereError(false);
                setCycleTimeData(null);
                setIsLoading(true);

                const response = await get_cycleTime();
                if (!ignore) {
                    setCycleTimeData(
                        response.data.cycle_time_data.map((item) => ({
                            week: item.week,
                            transfer: parseFloat(item.xfer),
                            "wait for acetylator": parseFloat(
                                item.wait_for_acet
                            ),
                            heatup: parseFloat(item.heatup),
                            "vac torque": parseFloat(item.vac_torque),
                            extrusion: parseFloat(item.extrusion),
                            cool: parseFloat(item.cool),
                            baseline: parseFloat(item.baseline),
                            "target improvement": parseFloat(
                                item["target_15%_improvement"]
                            ),
                            "four-week moving average": parseFloat(
                                item.four_wk_moving_average
                            ),
                            "phase four-week moving average": parseFloat(
                                item.phase_four_wk_moving_average
                            ),
                            "phase baseline loss": parseFloat(
                                item.phase_baseline_loss
                            ),
                            "phase heatup": parseFloat(item.phase_heatup),
                        }))
                    );
                }
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setIsLoading(false);
                setIsThereError(true);
            }
        }
        let ignore = false;
        if (location.pathname === "/dashboard/process-flow") {
            loadProcessFlowData();
        }
        if (location.pathname === "/dashboard/visualizer") {
            loadVisualizerData();
        }
        if (location.pathname === "/dashboard/pareto") {
            loadParetoData();
        }
        if (location.pathname === "/dashboard/cycle-time") {
            loadCycleTimeData();
        }

        return () => {
            ignore = true;
        };
    }, [customRange, visualizerNewData, location.pathname]);

    function getMinutesInteger(timeString) {
        const timeArray = timeString.split(":");
        const hours = parseInt(timeArray[0]);
        const minutes = parseInt(timeArray[1]);
        return hours * 60 + minutes;
    }

    function milliSecondsToStringOfHoursMinutes(timeInMilliseconds) {
        const minuteInMilliseconds = 1000 * 60;
        const hoursInMilliseconds = minuteInMilliseconds * 60;

        const hours = Math.floor(timeInMilliseconds / hoursInMilliseconds);

        const minutes = Math.floor(
            (timeInMilliseconds % hoursInMilliseconds) / minuteInMilliseconds
        );
        return `${hours.toString().length < 2 ? "0" + hours : hours}:${
            minutes.toString().length < 2 ? "0" + minutes : minutes
        }`;
    }

    function getStartTimeForLostTimeBar(goldenCycleDataArray, item) {
        let date = new Date(item.phase_start_time);

        const timeArray = efficientlyGetGoldenDuration(
            goldenCycleDataArray,
            item
        )?.split(":");

        if (timeArray !== undefined) {
            const hours = parseInt(timeArray[0]);
            const minutes = parseInt(timeArray[1]);
            const seconds = parseInt(timeArray[2]);
            date.setHours(date.getHours() + hours);
            date.setMinutes(date.getMinutes() + minutes);
            date.setSeconds(date.getSeconds() + seconds);

            const newTimestamp = date.getTime();
            return newTimestamp;
        }
        return undefined;
    }

    function getGoldenDuration(goldenCycleDataArray, item) {
        return goldenCycleDataArray.find(
            (goldenEntry) =>
                goldenEntry.asset === item.asset &&
                goldenEntry.product === item.product &&
                goldenEntry.phase === item.phase
        )?.phase_golden_duration;
    }

    function memoizeGetGoldenDuration(func1) {
        let cache = {};
        return function (...args) {
            const cacheKey = JSON.stringify(args);
            if (!(cacheKey in cache)) {
                cache[cacheKey] = func1(...args);
            }
            return cache[cacheKey];
        };
    }

    const efficientlyGetGoldenDuration =
        memoizeGetGoldenDuration(getGoldenDuration);

    if (location.pathname === "/dashboard/process-flow") {
        if (!processFlowData || !assetList || !sensorList) return;
    }
    if (location.pathname === "/dashboard/visualizer") {
        if (!visualizerData) return;
    }
    if (location.pathname === "/dashboard/pareto") {
        if (!paretoData) return;
    }
    if (location.pathname === "/dashboard/cycle-time") {
        if (!cycleTimeData) return;
    }

    function toggleInsights() {
        setShowInsights((prevShowInsights) => !prevShowInsights);
        setShowDateRange(false);
    }
    function toggleDateRange() {
        setShowDateRange((prevShowDateRange) => !prevShowDateRange);
        setShowInsights(false);
    }

    function createTooltip(tooltipClassName) {
        return d3
            .selectAll(`.${tooltipClassName}`)
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-color", "#B9B9B9")
            .style("border-width", "1px")
            .style("border-radius", "9px")
            .style("padding", "10px")
            .style("z-index", "10")
            .style("color", "#616161")
            .style("font-size", "12px");
    }

    function callSensorAxis(svg, axisClassName, dimensions, axis) {
        svg.select(`.${axisClassName}`)
            .attr("transform", `translate(${dimensions.width}, 0)`)
            .call(axis)
            .selectAll(".tick text")
            .style("color", "#0000FF");
    }

    function createSensorLines(
        areSensorsShowing,
        svg,
        lineClassName,
        sensorData,
        line,
        sensorName,
        sensorColor,
        assetSensorData,
        mouseenter,
        mouseleave
    ) {
        areSensorsShowing && assetSensorData.length > 0
            ? svg
                  .selectAll(`.${lineClassName}`)
                  .data([sensorData])
                  .join("path")
                  .attr("class", lineClassName)
                  .attr("fill", "none")
                  .attr("stroke", sensorColor)
                  .attr("stroke-width", 1)
                  .attr("d", line)
                  .attr("clip-path", "url(#clip)")
                  .on("mouseenter", mouseenter)
                  .on("mouseleave", mouseleave)
            : svg.selectAll(`.${lineClassName}`).remove("d", line);
    }

    function createAssetPhasesAndOpportunityBars(
        svg,
        phaseClassName,
        goldenBarClassName,
        timeLostBarClassName,
        batchClassName,
        data,
        yScaleData,
        batchData,
        xScale,
        yScale,
        mouseenter,
        mouseleave,
        mouseEnterBatch,
        mouseLeaveBatch,
        batchTextClassName
    ) {
        svg.selectAll(`.${phaseClassName}`)
            .data(data)
            .join("rect")
            .attr("class", phaseClassName)
            .attr("x", (d) => {
                return xScale(d.phase_start_time);
            })
            .attr("y", yScale(yScaleData))
            .attr("width", (d) => {
                return xScale(d.phase_end_time) - xScale(d.phase_start_time);
            })
            .attr("height", yScale.bandwidth())
            .attr("fill", (d) => d.color)
            .attr("clip-path", "url(#clip)")
            .on("mouseenter", mouseenter)
            .on("mouseleave", mouseleave);

        svg.selectAll(`.${batchClassName}`)
            .data(batchData)
            .join("rect")
            .attr("class", batchClassName)
            .attr("x", (d) => xScale(d.batch_start_time))
            .attr("y", yScale(yScaleData))
            .attr("width", (d) => {
                return xScale(d.batch_end_time) - xScale(d.batch_start_time);
            })
            .attr("height", yScale.bandwidth() / 5)
            .attr("fill", (d) => d.color)
            .attr("clip-path", "url(#clip)")
            .style("stroke", "grey")
            .raise()
            .on("mouseenter", mouseEnterBatch)
            .on("mouseleave", mouseLeaveBatch);

        const userAgent = navigator.userAgent;

        if (
            userAgent.indexOf("Chrome") > -1 ||
            !userAgent.toLocaleLowerCase().includes("safari")
        ) {
            svg.selectAll(`.${batchTextClassName}`)
                .data(batchData)
                .join("text")
                .attr("class", batchTextClassName)
                .attr("x", (d) => xScale(d.batch_start_time) + 3)
                .attr("y", yScale(yScaleData) + yScale.bandwidth() / 5 / 2)
                .attr("width", (d) => {
                    return (
                        xScale(d.batch_end_time) - xScale(d.batch_start_time)
                    );
                })
                .attr("height", yScale.bandwidth() / 5)
                .attr("transform", `translate(0,5)`)
                .text((d) => d.batch)
                .raise()
                .style("font-size", "10px")
                .attr("clip-path", "url(#clip)");
        } else if (userAgent.indexOf("Safari") > -1) {
            svg.selectAll(`.${batchTextClassName}`)
                .data(batchData)
                .join("foreignObject")
                .attr("class", batchTextClassName)
                .attr("x", (d) => xScale(d.batch_start_time) + 3)
                .attr(
                    "y",
                    yScale(yScaleData) +
                        yScale.bandwidth() / 5 -
                        yScale.bandwidth() / 5 / 4.5 -
                        11
                )
                .attr(
                    "width",
                    (d) => xScale(d.batch_end_time) - xScale(d.batch_start_time)
                )
                .attr("height", yScale.bandwidth() / 5)
                .html(
                    (d) =>
                        `<div class="text-container"  clip-path="url(#clip)">${d.batch}</div>`
                )
                .attr("text-anchor", "start")
                .raise()
                .attr("clip-path", "url(#clip)");
        }

        const opportunityBar = (
            barIdentifier,
            className,
            fillColor,
            xAttribute,
            widthAttributeStart,
            widthAttributeEnd
        ) => {
            return svg
                .selectAll(`.${className}`)
                .data(data)
                .join("rect")
                .attr("class", className)
                .attr("x", (d) => {
                    if (d.phase_golden_cycle_end_time !== undefined) {
                        if (d.phase_end_time < d.phase_golden_cycle_end_time) {
                            if (barIdentifier.timeLostBar) {
                                return xScale(d.phase_end_time);
                            } else return xScale(d[xAttribute]);
                        }
                        return xScale(d[xAttribute]);
                    }
                })
                .attr("y", yScale(yScaleData))
                .attr("width", (d) => {
                    if (d.phase_golden_cycle_end_time !== undefined) {
                        if (d.phase_end_time < d.phase_golden_cycle_end_time) {
                            if (barIdentifier.goldenBar) {
                                return (
                                    xScale(d.phase_end_time) -
                                    xScale(d.phase_start_time)
                                );
                            } else {
                                return (
                                    xScale(d.phase_end_time) -
                                    xScale(d.phase_end_time)
                                );
                            }
                        } else {
                            return (
                                xScale(d[widthAttributeEnd]) -
                                xScale(d[widthAttributeStart])
                            );
                        }
                    }
                })
                .attr(
                    "height",
                    isPhaseMax === true
                        ? yScale.bandwidth() / 5
                        : (yScale.bandwidth() / 5) * 3
                )
                .attr("fill", fillColor)
                .attr(
                    "transform",
                    `translate(0,${
                        isPhaseMax === true
                            ? yScale.bandwidth() - yScale.bandwidth() / 5
                            : yScale.bandwidth() - (yScale.bandwidth() / 5) * 3
                    })`
                )
                .attr("clip-path", "url(#clip)");
        };
        opportunityBar(
            { goldenBar: true },
            goldenBarClassName,
            "#D1F6CA",
            "phase_start_time",
            "phase_start_time",
            "phase_golden_cycle_end_time"
        );
        opportunityBar(
            { timeLostBar: true },
            timeLostBarClassName,
            "#BC0002",
            "phase_golden_cycle_end_time",
            "phase_golden_cycle_end_time",
            "phase_end_time"
        );
    }

    return (
        <ChartsContext.Provider
            value={{
                visualizerData,
                setVisualizerData,
                assetList,
                setAssetList,
                paretoData,
                paretoTableData,
                cycleTimeData,
                toggleDateRange,
                toggleInsights,
                showInsights,
                showDateRange,
                setCycleTimeUptimeView,
                setCycleTimePhaseView,
                // dateParams,
                // setDateParams,
                isLoading,
                createTooltip,
                createAssetPhasesAndOpportunityBars,
                createSensorLines,
                callSensorAxis,
                baselineEndDate,
                baselineStartDate,
                setBaselineStartDate,
                setBaselineEndDate,
                sensorData,
                sensorList,
                assetSensorList,
                sensorsColors,
                efficientlyGetGoldenDuration,
                memoizeGetGoldenDuration,
                getGoldenDuration,
                getStartTimeForLostTimeBar,
                dataStartLimit,
                dataEndLimit,
                isSensorShowing,
                setIsSensorShowing,
                isSensorShowingOnAsset,
                setIsSensorShowingOnAsset,
                processFlowData,
                setProcessFlowData,
                setIsDateChange,
                currentRangeSensorList,
                setIsFileActive,
                setIsPhaseMax,
                isPhaseMax,
                customRange,
                setCustomRange,
                graphStartDate,
                graphEndDate,
                setGraphStartDate,
                setGraphEndDate,
                startDate,
                endDate,
                setStartDate,
                setEndDate,
                allAssetPhase,
                getVisualizerAndSensorData,
                phaseData,
                setPhaseData,
                productData,
                setProductData,
                setDefaultPhase,
                setAllAssetPhase,
                defaultPhase,
            }}
        >
            {props.children}
        </ChartsContext.Provider>
    );
}
export { ChartsContextProvider, ChartsContext };
