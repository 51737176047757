import SensorToggle from "./SensorToggle";

function SensorToggleMenu({ menuRef }) {
    return (
        <div
            className="absolute top-[130%] bg-white z-10 left-0 w-full border border-border p-3 space-y-2 rounded-md"
            ref={menuRef}
        >
            <SensorToggle />
        </div>
    );
}

export default SensorToggleMenu;
