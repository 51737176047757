import LoginForm from "./LoginForm";
import LoginPageScreen from "./LoginPageScreen";
import { Navigate } from "react-router-dom";
import { Context } from "../../contexts/Context";
import { useContext } from "react";
import LoadingScreen from "../../components/loadingComponents/LoadingScreen";

export default function LoginPage() {
    const { currentAccessToken, isLoading } = useContext(Context);

    if (currentAccessToken) {
        return <Navigate to="/dashboard/visualizer" replace={true} />;
    }

    return (
        <div className="flex w-full h-screen relative">
            {isLoading && <LoadingScreen />}

            <LoginPageScreen />
            <LoginForm />
        </div>
    );
}
