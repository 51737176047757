import { createContext, useState,useEffect } from "react";
import { check_file_progress} from '../services/api-calls'
import { useLocation } from "react-router-dom";
const Context = createContext();

function ContextProvider(props) {
    const [currentAccessToken, setCurrentAccessToken] = useState(() =>
        localStorage.getItem("accessToken")
    );
    const [showFileProgressMessage, setFileProgressMessage] = useState(false);
    const [currentUserName, setCurrentUserName] = useState(() =>
        localStorage.getItem("username")
    );
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isFileActivationProcess, setFileActivationProcess] = useState(false);
    const [cycleTimeUptimeView, setCycleTimeUptimeView] = useState(true);
    const [cycleTimePhaseView, setCycleTimePhaseView] = useState(false);
    const [config, setConfig] = useState(() =>
        JSON.parse(localStorage.getItem("configData"))
    );

    const [isBaselineApplied, setIsBaselineApplied] = useState(false);
    const [isBaselineAppliedSuccessfully, setIsBaselineAppliedSuccessfully] =
        useState(false);
    const [shouldPopupOpen, setShouldPopupOpen] = useState(false);
    const [isThereError, setIsThereError] = useState(false);


    function setAccessToken() {
        setCurrentAccessToken(localStorage.getItem("accessToken"));
    }

    function toggleCycleTimeCharts() {
        setCycleTimeUptimeView(
            (prevCycleTimeUptimeView) => !prevCycleTimeUptimeView
        );
        setCycleTimePhaseView(
            (prevCycleTimePhaseView) => !prevCycleTimePhaseView
        );
    }

    function logout() {
        localStorage.clear();
        setIsThereError(false);
        setCurrentAccessToken(null);
    }
    useEffect(() => {
        checkProgress();
    }, [location.pathname]);
    const checkProgress = async () => {
        try {
            const response = await check_file_progress();
            if (
                response.code === 200 &&
                (response.data.upload_in_progress ||
                    response.data.sensor_upload_in_progress)
            ) {
                setFileProgressMessage(true);
                async function checkFileProgress() {
                    try {
                        const response = await check_file_progress();
                        if (
                            !response.data.upload_in_progress &&
                            !response.data.sensor_upload_in_progress
                        ) {
                            clearInterval(intervalID);
                            setFileProgressMessage(false);
                            setFileActivationProcess(true)
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
                const intervalID = setInterval(checkFileProgress, 3000);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Context.Provider
            value={{
                currentAccessToken,
                setAccessToken,
                logout,
                cycleTimeUptimeView,
                cycleTimePhaseView,
                toggleCycleTimeCharts,
                setCycleTimeUptimeView,
                setCycleTimePhaseView,
                isLoading,
                setIsLoading,
                config,
                setConfig,
                isBaselineApplied,
                setIsBaselineApplied,
                isBaselineAppliedSuccessfully,
                setIsBaselineAppliedSuccessfully,
                shouldPopupOpen,
                setShouldPopupOpen,
                isThereError,
                setIsThereError,
                currentUserName,
                setCurrentUserName,
                setFileActivationProcess,
                isFileActivationProcess,
                showFileProgressMessage,
                checkProgress,
            }}
        >
            {props.children}
        </Context.Provider>
    );
}

export { ContextProvider, Context };
