import React, { useState, useEffect, useContext } from "react";
import { productPhase } from "../services/api-calls";
import FiltersComponent from "./FiltersComponent";
import { ChartsContext } from "../contexts/ChartsContext";

const FiltersContainer = ({ showAssets, toggleAssets, FilterIcon }) => {
    const [users, setUsers] = useState([]);
    const [usersPhase, setUsersPhase] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searchValuePhase, setSearchValuePhase] = useState("");
    const [productFilterData, setProductFilterData] = useState([]);
    const [phaseFilterData, setPhaseFilterData] = useState([]);
    const [reset, setReset] = useState(false);
    const [resetPhase, setResetPhase] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isSearchActivePhase, setIsSearchActivePhase] = useState(false);
    const [nProd, setNprod] = useState("");
    const [nPhase, setNphase] = useState("");
    const [prevUsers, setPrevUsers] = useState([]);
    const [prevUsersPhase, setPrevUsersPhase] = useState([]);
    const { setPhaseData, setProductData } = useContext(ChartsContext);
    useEffect(() => {
        const bundledData = async () => {
            const response = await productPhase();
            setNprod(response?.data?.products);
            setNphase(response?.data?.phase);
        };

        bundledData();
    }, []);
    useEffect(() => {
        if (nProd && nPhase) {
            setUsers(nProd.map((user) => ({ name: user, isChecked: false })));
            setUsersPhase(
                nPhase.map((user) => ({ name: user, isChecked: false }))
            );
        }
    }, [nProd, nPhase]);

    const handleSearchChange = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchValue(value);
        setIsSearchActive(value !== "");
    };
    const handleSearchChangePhase = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchValuePhase(value);
        setIsSearchActivePhase(value !== "");
    };

    const handleChange = (name, checked) => {
        const updatedUsers = users.map((user) =>
            user.name === name ? { ...user, isChecked: checked } : user
        );
        setUsers(updatedUsers);

        const allSelected = updatedUsers.every((user) => user.isChecked);
        const noneSelected = updatedUsers.every((user) => !user.isChecked);

        if (allSelected) {
            setProductFilterData(users.map((user) => user.name)); // Store product data
        } else if (noneSelected) {
            setProductFilterData([]);
        } else {
            setProductFilterData(
                updatedUsers
                    .filter((user) => user.isChecked)
                    .map((user) => user.name)
            );
        }
    };
    const handleChangePhase = (name, checked) => {
        const updatedUsers = usersPhase.map((user) =>
            user.name === name ? { ...user, isChecked: checked } : user
        );
        setUsersPhase(updatedUsers);

        const allSelected = updatedUsers.every((user) => user.isChecked);
        const noneSelected = updatedUsers.every((user) => !user.isChecked);

        if (allSelected) {
            setPhaseFilterData(usersPhase.map((user) => user.name)); // Store phase data
        } else if (noneSelected) {
            setPhaseFilterData([]);
        } else {
            setPhaseFilterData(
                updatedUsers
                    .filter((user) => user.isChecked)
                    .map((user) => user.name)
            );
        }
    };

    const handleReset = () => {
        const updatedUsers = users.map((user) => ({
            ...user,
            isChecked: false,
        }));
        setUsers(updatedUsers);
        setReset(!reset);
        setProductFilterData([]);
        setUsersPhase(updatedUsers);
        setResetPhase(!resetPhase);
        setPhaseFilterData([]);
    };
    const handleSelectAll = (e) => {
        const { checked } = e.target;
        const updatedUsers = users.map((user) => ({
            ...user,
            isChecked: checked,
        }));
        setUsers(updatedUsers);
        setProductFilterData([]);
    };
    const handleSelectAllPhase = (e) => {
        const { checked } = e.target;
        const updatedUsers = usersPhase.map((user) => ({
            ...user,
            isChecked: checked,
        }));
        setUsersPhase(updatedUsers);
        setPhaseFilterData([]);
    };
    const filteredUsers = users.filter((user) =>
        user.name.toLowerCase().includes(searchValue)
    );
    const filteredUsersPhase = usersPhase.filter((user) =>
        user.name.toLowerCase().includes(searchValuePhase)
    );
    const handleApply = () => {
        setPrevUsers(users);
        setPrevUsersPhase(usersPhase);
        setProductData(productFilterData);
        setPhaseData(phaseFilterData);
        setSearchValue("");
        setSearchValuePhase("");
        toggleAssets();
    };
    const handleCancel = () => {
        if (prevUsers.length !== 0) {
            setUsers(prevUsers);
            setUsersPhase(prevUsersPhase);
        }
        setSearchValue("");
        setSearchValuePhase("");
        toggleAssets();
    };

    return (
        <>
            <div
                className={`border border-border ml-[30px] p-1 px-7 rounded-md h-min ${
                    showAssets ? "bg-[#1C7E41]" : ""
                } flex items-center justify-center  text-center cursor-pointer`}
                onClick={toggleAssets}
            >
                {/* <div className="flex justify-center "> */}
                <FilterIcon
                    className={`w-4 mr-1 ${showAssets ? "fill-white" : ""}`}
                />
                <p
                    className={`${
                        showAssets ? "text-white" : "text-textColor"
                    } text-sm whitespace-nowrap w-full cursor-pointer`}
                >
                    Filter
                </p>
                {/* </div> */}
            </div>
            <>
                {showAssets && (
                    <div
                        className={`bg-[#FFFFFF] border-[#EFEFEF] border-[1px] mt-[25px] rounded-[11px] p-3 flex flex-col items-center gap-4 absolute w-full h-[1000px] right-0 top-5`}
                    >
                        <FiltersComponent
                            users={users}
                            filteredUsers={filteredUsers}
                            searchValue={searchValue}
                            isSearchActive={isSearchActive}
                            handleReset={handleReset}
                            handleSearchChange={handleSearchChange}
                            handleChange={handleChange}
                            handleSelectAll={handleSelectAll}
                            Title={"Product"}
                            searchPlaceHolder={"Search Product"}
                            id={"Product"}
                            clicked={true}
                        />
                        {/* /////////////////////////////////////////// */}
                        <FiltersComponent
                            users={usersPhase}
                            filteredUsers={filteredUsersPhase}
                            searchValue={searchValuePhase}
                            isSearchActive={isSearchActivePhase}
                            handleSearchChange={handleSearchChangePhase}
                            handleChange={handleChangePhase}
                            handleSelectAll={handleSelectAllPhase}
                            Title={"Phase"}
                            searchPlaceHolder={"Search Phase"}
                            id={"Phase"}
                            clicked={false}
                        />
                        <div className="mt-[30px] pr-[20px] flex">
                            <button
                                className=" rounded-md ml-[80px] mr-[10px] bg-white w-[80px] h-[30px] shadow-black shadow-md dark:shadow-lg"
                                onClick={handleCancel}
                            >
                                <h1 className=" Medium">Cancel</h1>
                            </button>
                            <button
                                className=" rounded-md bg-[#1C7E41] w-[80px] h-[30px]"
                                onClick={handleApply}
                            >
                                <h1 className="text-white Medium">Apply</h1>
                            </button>
                        </div>
                    </div>
                )}
            </>
        </>
    );
};

export default FiltersContainer;
