export default function SingleLegendIdentifier({ name, style, legendType }) {
    return (
        <div className="flex gap-2 items-center">
            {legendType.color ? (
                <div
                    className="w-5 h-5 rounded-lg basis-5 shrink-0"
                    style={{ backgroundColor: style }}
                ></div>
            ) : (
                <div
                    className={`w-5 basis-5 shrink-0 border-t-2 ${style}`}
                ></div>
            )}

            <p className="text-textColor text-base">{name}</p>
        </div>
    );
}
