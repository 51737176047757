import { useContext, Fragment } from "react";
import { ChartsContext } from "../../contexts/ChartsContext";

function SensorToggle() {
    const {
        isSensorShowingOnAsset,
        setIsSensorShowingOnAsset,
        sensorData,
        currentRangeSensorList,
        assetSensorList,
    } = useContext(ChartsContext);

    function toggleSensor(e) {
        const { name, checked } = e.target;
        setIsSensorShowingOnAsset((prevIsSensorShowingOnAsset) => ({
            ...prevIsSensorShowingOnAsset,
            [name]: checked,
        }));
    }
    const assetListForSensorToggleElements = !sensorData ? (
        <p className="text-[10px] text-textColor">
            Sensor data is loading. Please wait.
        </p>
    ) : sensorData.length > 0 ? (
        Object.keys(isSensorShowingOnAsset).length > 0 ? (
            Object.entries(isSensorShowingOnAsset).map(([key, value]) => (
                <Fragment key={key}>
                    {" "}
                    <label
                        className={`relative flex items-center space-between gap-4 w-full`}
                        // key={key}
                    >
                        <input
                            type="checkbox"
                            value=""
                            name={key}
                            checked={value}
                            onChange={toggleSensor}
                            className="sr-only peer cursor-pointer"
                            disabled={assetSensorList[key].length < 1}
                        />
                        <span className="text-xs text-textColor w-[70%] cursor-pointer peer-disabled:text-textColor2 peer-disabled:cursor-default">
                            {key}
                        </span>

                        <div className="relative text-[10px] flex items-center pl-[6px] justify-end pr-[6px] w-5 h-3 cursor-pointer bg-textColor2 peer-disabled:bg-tableBackground2 peer-disabled:cursor-default peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-[100%] peer-checked:after:border-white after:content-[''] after:absolute after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-2 after:w-2 after:transition-all peer-checked:bg-primary peer-checked:justify-start"></div>
                        <p className="text-[10px] text-textColor bg-slate-100 p-1 rounded-sm border border-textColor2 absolute z-20 top-full left-5 -right-6 hidden peer-hover:peer-disabled:block">
                            Go to{" "}
                            <span className="text-primary font-semibold">
                                Process Flow
                            </span>{" "}
                            to assign sensors
                        </p>
                    </label>
                </Fragment>
            ))
        ) : (
            <p className="text-[10px] text-textColor">
                Please upload phase data to toggle sensors data on assets.
            </p>
        )
    ) : (
        <p className="text-[10px] text-textColor">
            No sensor data available for graph's selected range.
        </p>
    );
    return <>{assetListForSensorToggleElements}</>;
}
export default SensorToggle;
