import { useContext, useEffect, useState } from "react";
import { ReactComponent as LoginIcon } from "../../assets/loginPage/login.svg";
import { ReactComponent as PasswordIcon } from "../../assets/loginPage/password.svg";
import { ReactComponent as ShowPassword } from "../../assets/loginPage/show-password.svg";
import { ReactComponent as HidePassword } from "../../assets/loginPage/hide-password.svg";
import { ReactComponent as Logo } from "../../assets/logo-green.svg";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../services/api-calls";
import { Context } from "../../contexts/Context";

export default function LoginForm() {
    const { setAccessToken, isLoading, setIsLoading, setCurrentUserName } =
        useContext(Context);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        rememberMe: false,
    });
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        email: "",
        password: "",
    });
    const [errorDisplay, setErrorDisplay] = useState({
        email: "hidden",
        password: "hidden",
    });

    useEffect(() => {
        const loginUser = async () => {
            try {
                if (
                    isSubmitted &&
                    errorDisplay["email"] === "hidden" &&
                    errorDisplay["password"] === "hidden"
                ) {
                    // debugger;
                    setIsLoading(true);
                    const response = await login(formData);
                    localStorage.setItem(
                        "accessToken",
                        `Bearer ${response.data.access_token}`
                    );
                    localStorage.setItem(
                        "username",
                        `${response.data.user_data.name}`
                    );
                    setCurrentUserName(localStorage.getItem("username"));
                    setAccessToken();
                    navigate("/dashboard/visualizer", { replace: true });
                    setIsLoading(false);
                }
            } catch (e) {
                console.log(e);
                setErrorDisplay((prevErrorDisplay) => ({
                    ...prevErrorDisplay,
                    email: "block",
                }));
                setErrorMessage((prevErrorMessage) => ({
                    ...prevErrorMessage,
                    email: e.response.data.message,
                }));
                setIsLoading(false);
                setIsSubmitted(false);
            }
        };
        loginUser();
    }, [isSubmitted]);

    function handleChange(e) {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === "checkbox" ? checked : value,
        }));
        setIsSubmitted(false);
        setErrorDisplay((prevErrorDisplay) => ({
            ...prevErrorDisplay,
            [name]: "hidden",
            email: "hidden",
        }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (!formData.email) {
            setErrorDisplay((prevErrorDisplay) => ({
                ...prevErrorDisplay,
                email: "block",
            }));
            setErrorMessage((prevErrorMessage) => ({
                ...prevErrorMessage,
                email: "Email can't be empty",
            }));
        }
        if (!formData.password) {
            setErrorDisplay((prevErrorDisplay) => ({
                ...prevErrorDisplay,
                password: "block",
            }));
            setErrorMessage((prevErrorMessage) => ({
                ...prevErrorMessage,
                password: "Password can't be empty",
            }));
        }
        setIsSubmitted(true);
    }

    function togglePasswordVisibility() {
        setShowPassword((showPassword) => !showPassword);
    }

    const inputClasses =
        "text-base outline-none placeholder-transparent peer w-full";
    const labelClasses =
        "absolute left-0 bottom-full text-label text-sm pointer-events-none peer-placeholder-shown:text-base peer-placeholder-shown:bottom-auto transition-all ease-linear duration-300 peer-focus:bottom-full peer-focus:text-label peer-focus:text-sm";
    return (
        <div
            className={`flex-1 flex flex-col justify-center items-center w-1/2 gap-5 py-5 ${
                isLoading && "cursor-wait"
            }`}
        >
            <Logo className="md:hidden" />
            <div className="space-y-12 w-full px-[clamp(1rem,-12.3571rem+24.2857vw,9.5rem)]">
                <p className="text-primary text-3xl text">Login</p>
                <form
                    className="flex flex-col gap-12 justify-center"
                    onSubmit={handleSubmit}
                >
                    <div className="flex items-center gap-6 border-b border-b-border pb-4 relative">
                        <LoginIcon className="w-4" />
                        <div className="relative w-full">
                            <input
                                placeholder="Enter Your Email"
                                value={formData.email}
                                name="email"
                                type="email"
                                id="email"
                                onChange={handleChange}
                                className={inputClasses}
                            />
                            <label htmlFor="email" className={labelClasses}>
                                Enter Your Email
                            </label>
                        </div>
                        <p
                            className={`${errorDisplay["email"]} text-[13px] font-bold leading-tight text-red-800 absolute top-[110%] left-10`}
                        >
                            {errorMessage["email"]}
                        </p>
                    </div>

                    <div className="flex items-center gap-6 border-b border-b-border pb-4 relative">
                        <PasswordIcon className="w-4" />
                        <div className="relative w-full">
                            <input
                                placeholder="Enter Your Password"
                                value={formData.password}
                                name="password"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                onChange={handleChange}
                                className={inputClasses}
                            />
                            <label htmlFor="password" className={labelClasses}>
                                Enter Your Password
                            </label>
                        </div>
                        {showPassword ? (
                            <ShowPassword
                                onClick={togglePasswordVisibility}
                                className="absolute right-0 w-6"
                            />
                        ) : (
                            <HidePassword
                                onClick={togglePasswordVisibility}
                                className="absolute right-0 w-6"
                            />
                        )}
                        <p
                            className={`${errorDisplay["password"]} text-[13px] font-bold leading-tight text-red-800 absolute top-[110%] left-10`}
                        >
                            {errorMessage["password"]}
                        </p>
                    </div>
                    <div className="flex items-center gap-[6px] relative">
                        <input
                            name="rememberMe"
                            type="checkbox"
                            className="w-3 h-3"
                        />
                        <label className="text-base">Remember Me</label>
                        <Link to="#" className="text-base absolute right-0">
                            Forgot Password?
                        </Link>
                    </div>
                    <button
                        className={`text-[21px] text-white bg-primary py-2 px-28 rounded-[12px] mx-auto mt-24 ${
                            isLoading && "pointer-events-none"
                        }`}
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
}
