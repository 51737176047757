import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ContextProvider } from "./contexts/Context";
import "./styles/scss-for-datepicker/datepicker.scss";
// import "react-datepicker/dist/react-datepicker.css";

import "./styles/index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
        <Router>
    <ContextProvider>
            {/* <React.StrictMode> */}
        <App />
            {/* </React.StrictMode> */}
    </ContextProvider>
        </Router>
);
