import { ChartsContextProvider } from "../contexts/ChartsContext";
import { Context } from "../contexts/Context";
import { useContext } from "react";
import RefreshModal from "./RefreshModal";

function ContentContainer(props) {
    const { isLoading, isBaselineApplied, isFileActivationProcess } =
        useContext(Context);
    return (
        <ChartsContextProvider>
            <div
                className={`relative ml-28 mr-14 pt-28 pb-24 min-h-screen  ${
                    (isLoading || isBaselineApplied) && "cursor-wait"
                }`}
            >
                {isFileActivationProcess === false ? (
                    props.children
                ) : (
                    <RefreshModal />
                )}
            </div>
        </ChartsContextProvider>
    );
}

export default ContentContainer;
