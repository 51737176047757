import { useContext } from "react";
import SensorToggleMenu from "./SensorToggleMenu";
import { ReactComponent as UpwardArrow } from "../../assets/insightsIcons/upward-arrow.svg";
import { ReactComponent as DownwardArrow } from "../../assets/insightsIcons/downward-arrow.svg";
import { Context } from "../../contexts/Context";
// import ToggleButtons from "../../components/ToggleButtons";
// import { ChartsContext } from "../../contexts/ChartsContext";

function SensorOnAssetToggle({
    showSensorToggleMenu,
    toggleShowSensorMenu,
    FilterIcon,
    menuRef,
}) {
    const { isLoading } = useContext(Context);

    return (
        <div className="relative flex  gap-4 self-end">
            <div
                className={`border border-border p-1 px-3 rounded-md h-min flex items-center justify-between w-[139px] ${
                    isLoading ? "pointer-events-none" : "cursor-pointer"
                }`}
                onClick={(e) => toggleShowSensorMenu(e)}
            >
                <FilterIcon className="w-4 mr-1" />
                <p className="text-textColor text-sm whitespace-nowrap w-full mr-2">
                    Sensor Filter
                </p>

                {showSensorToggleMenu ? <UpwardArrow /> : <DownwardArrow />}
            </div>
            {showSensorToggleMenu && <SensorToggleMenu menuRef={menuRef} />}
        </div>
    );
}

export default SensorOnAssetToggle;
