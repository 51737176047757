import { ReactComponent as InsightsIcon } from "../assets/insightsIcons/insights-icon.svg";
import { ReactComponent as CancelIcon } from "../assets/insightsIcons/cancel-cross.svg";
import { ReactComponent as RefreshIcon } from "../assets/insightsIcons/refresh.svg";
import { ReactComponent as BackArrow } from "../assets/insightsIcons/backward-arrow.svg";
import { ReactComponent as ForwardArrow } from "../assets/insightsIcons/forward-arrow.svg";
import { ReactComponent as NoInsightIcon } from "../assets/insightsIcons/no-insights-icon.svg";
import Button from "./Button";
import { useState } from "react";

function InsightsContainer({
    insightData,
    toggleInsights,
    showInsights,
    insightDates,
}) {
    const [insightIndex, setInsightIndex] = useState(0);
    const insightsElements = insightData.map((insight, index) => {
        if (insightIndex === index) {
            return insight;
        } else return null;
    });
    const insightDatesElement = insightDates.map((insightDate, index) => {
        if (insightIndex === index) {
            return insightDate;
        } else return null;
    });

    return (
        <div className="flex flex-col">
            <Button
                showButtonDropDown={showInsights}
                toggleShowHide={toggleInsights}
                Icon={InsightsIcon}
                buttonText="Insights"
            />
            {showInsights && (
                <div
                    className={`bg-insightsBackground rounded-[11px] p-3 flex flex-col z-10 items-center absolute w-full ${
                        insightData.length > 0 && ""
                    } right-0 top-9`}
                >
                    <div className="flex items-center gap-2 w-full">
                        <p className="mr-auto text-textColor1">
                            Graph Insights
                        </p>
                        <RefreshIcon className="w-4 cursor-pointer" />
                        <CancelIcon
                            className="w-[14px] cursor-pointer"
                            onClick={toggleInsights}
                        />
                    </div>
                    {insightDates.length > 0 && insightDatesElement}
                    {insightData.length > 0 ? (
                        insightsElements
                    ) : (
                        <div className="flex flex-col justify-center items-center gap-2 my-4 text-center">
                            <NoInsightIcon className="w-8" />
                            <p>There are no available insights at the moment</p>
                        </div>
                    )}
                    {insightData.length > 0 && (
                        <div className="flex items-center gap-2 mt-4">
                            <BackArrow
                                className={`w-[10px] cursor-pointer ${
                                    !insightIndex &&
                                    "pointer-events-none fill-[#bbb]"
                                }`}
                                onClick={() =>
                                    setInsightIndex(
                                        (prevInsightIndex) =>
                                            prevInsightIndex - 1
                                    )
                                }
                            />
                            <p className="text-textColor">{`${
                                insightIndex + 1
                            } of ${insightData.length}`}</p>
                            <ForwardArrow
                                className={`w-[10px] cursor-pointer ${
                                    insightIndex === insightData.length - 1 &&
                                    "pointer-events-none fill-[#bbb]"
                                }`}
                                onClick={() =>
                                    setInsightIndex(
                                        (prevInsightIndex) =>
                                            prevInsightIndex + 1
                                    )
                                }
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default InsightsContainer;
