import { useContext } from "react";
import { Context } from "../contexts/Context";

function Button({ showButtonDropDown, toggleShowHide, Icon, buttonText }) {
    const { isLoading } = useContext(Context);
    return (
        <button
            className={`flex items-center gap-1 border border-border p-1 px-4 rounded-md relative w-min self-end ${
                showButtonDropDown
                    ? "bg-primary text-white"
                    : "bg-white text-textColor"
            } ${isLoading && "pointer-events-none"}`}
            onClick={toggleShowHide}
        >
            <Icon
                className={`${
                    showButtonDropDown ? "fill-white" : "fill-textColor"
                } h-[16.5px]`}
            />
            <span className="text-sm whitespace-nowrap">{buttonText}</span>
        </button>
    );
}

export default Button;
