const polymerizerColorsData = [
    {
        name: "Empty/Init",
        color: "#CBE7F6",
    },
    {
        name: "Wait for Acetylator",
        color: "#FB0201",
    },
    {
        name: "Transfer",
        color: "#FEF963",
    },
    {
        name: "Heating",
        color: "#FCC861",
    },
    {
        name: "VacTorque",
        color: "#FF9933",
    },
    {
        name: "Extrusion",
        color: "#FC6502",
    },
    {
        name: "Cool",
        color: "#FE797E",
    },
    {
        name: "Washing",
        color: "#A7A19F",
    },
];
const acetylatorColorsData = [
    {
        name: "Acetylator Cycle",
        color: "#FF95F7",
    },
    {
        name: "Sync Timer Pause",
        color: "#02ACE9",
    },
    {
        name: "Wait for Convey",
        color: "#34F633",
    },
    {
        name: "Transfer",
        color: "#FEF963",
    },
    {
        name: "Cool",
        color: "#FE797E",
    },
    {
        name: "Idle",
        color: "#CBE7F6",
    },
];

const opportunityLevelColors = [
    {
        name: "Optimum Time",
        color: "#D1F6CA",
    },
    {
        name: "Lost Time",
        color: "#BC0002",
    },
];

const cycleTimeUptimeColors = [
    {
        name: "Transfer",
        color: "#FDA50F",
    },
    {
        name: "Wait for Acet",
        color: "#C00000",
    },
    {
        name: "Heatup",
        color: "#FFD300",
    },
    {
        name: "Vac Torque",
        color: "#D6AD00",
    },
    {
        name: "Extrusion",
        color: "#FFFF66",
    },
    {
        name: "Cool",
        color: "#FFBF00",
    },
];

const lineIdentifierUptimeData = [
    { name: "Baseline", style: "border-black" },
    {
        name: "Target 15% ZLE Improvement",
        style: "border-dashed border-[#00C500]",
    },
    { name: "4-week Moving Average", style: "border-dotted border-[#002060]" },
];
const cycleTimePhaseColor = [
    {
        name: "Heatup",
        color: "#FF8282",
    },
];

export {
    polymerizerColorsData,
    opportunityLevelColors,
    acetylatorColorsData,
    cycleTimeUptimeColors,
    lineIdentifierUptimeData,
    cycleTimePhaseColor,
};
