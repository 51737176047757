function LoadingScreen() {
    return (
        <div className="flex justify-center items-center absolute w-full h-full">
            <div className="w-full h-full absolute bg-white z-10 opacity-80 flex justify-center items-center"></div>
            <div
                className="w-12 h-12 rounded-full animate-spin
                    border-2 border-solid border-blue-500 border-t-transparent shadow-md absolute z-20 opacity-100"
            ></div>
        </div>
    );
}

export default LoadingScreen;
