import ParetoChart from "./ParetoChart";
import BaselineComparisonChart from "./BaselineComparisonChart";
import { useState, useEffect } from "react";
import ToggleButtons from "../../components/ToggleButtons";
import InsightsContainer from "../../components/InsightsContainer";
import { paretoInsightData } from "../../data/insightsData";
import DateRangeContainer from "../../components/DateRangeContainer";
import YAxisLabel from "../../components/YAxisLabel";
import XAxisLabel from "../../components/XAxisLabel";

function ParetoChartsContainer() {
    const [baselineView, setBaselineView] = useState(true);
    const [paretoView, setParetoView] = useState(false);
    const [showInsights, setShowInsights] = useState(false);
    const [showDateRange, setShowDateRange] = useState(false);
    const [newInsights, setNewInsights] = useState(true);
    const [showBaselineDateRange, setShowBaselineDateRange] = useState(false);

    function toggleInsights() {
        setShowInsights((prevShowInsights) => !prevShowInsights);
        setNewInsights(false);
        setShowBaselineDateRange(false);
    }
    function toggleDateRange(e) {
        setShowDateRange((prevShowDateRange) => !prevShowDateRange);
        setShowInsights(false);
        setShowBaselineDateRange(false);
        e.stopPropagation();
    }

    // function toggleBaselineDateRange() {
    //     setShowBaselineDateRange(
    //         (prevShowBaselineDateRange) => !prevShowBaselineDateRange
    //     );
    //     setShowInsights(false);
    // }

    useEffect(() => {
        function hideDropDown(e) {
            setShowDateRange(false);
        }
        document.body.addEventListener("click", hideDropDown);
        return () => document.body.removeEventListener("click", hideDropDown);
    }, []);

    function handleClick() {
        setBaselineView((prevBaselineView) => !prevBaselineView);
        setParetoView((prevParetoView) => !prevParetoView);
    }

    return (
        <>
            <YAxisLabel
                labelText={baselineView ? "Phases" : "Percentage Loss"}
            />
            <div className="flex flex-col ml-7 gap-3">
                <div className="flex gap-3 self-end">
                    <ToggleButtons
                        firstOption={{ view: baselineView, text: "Baseline" }}
                        secondOption={{ view: paretoView, text: "Pareto" }}
                        handleClick={handleClick}
                    />
                </div>

                <div className="flex gap-4 flex-wrap mr-auto">
                    {baselineView && (
                        <div className="flex gap-2 items-center">
                            <div className="w-4 h-4 bg-baselineBar rounded-full"></div>
                            <p className="text-textColor1">Baseline</p>
                        </div>
                    )}
                    <div className="flex gap-2 items-center">
                        <div className="w-4 h-4 bg-actualWeekBar rounded-full"></div>
                        <p className="text-textColor1">
                            30 September 2022 week
                        </p>
                    </div>
                </div>

                {baselineView && <BaselineComparisonChart />}
                {paretoView && <ParetoChart />}
                <XAxisLabel
                    labelText={baselineView ? "Reactor Hours Lost" : "Phases"}
                    chartType="pareto"
                />
            </div>

            <div className="flex justify-end gap-4 relative">
                <DateRangeContainer
                    showDateRange={showDateRange}
                    toggleDateRange={toggleDateRange}
                    chartType="Pareto"
                />
                <InsightsContainer
                    insightData={paretoInsightData}
                    insightDates={[]}
                    showInsights={showInsights}
                    toggleInsights={toggleInsights}
                    newInsights={newInsights}
                />
            </div>
        </>
    );
}

export default ParetoChartsContainer;
