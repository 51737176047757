const paretoInsightData = [
    <p
        key={1}
        className="bg-white rounded-[11px] border border-insightsBorder p-4 shadow-[0px_3px_1px_#00000021] text-textColor mb-auto overflow-y-auto"
    >
        The <span className="font-bold text-black">Wash (x4)</span> phase is
        responsible for the most reactor hours lost, with 78.2 hours lost in the
        last week and 66.9 hours lost over the baseline period. This phase
        accounted for 58% of lost time in the last week.
    </p>,
    <p
        key={2}
        className="bg-white rounded-[11px] border border-insightsBorder p-4 shadow-[0px_3px_1px_#00000021] text-textColor mb-auto overflow-y-auto"
    >
        11.25 additional hours were lost compared to baseline during the Wash
        (x4) phase in the last week. 78.2 hours were lost in the last week
        compared to 66.9 hours during the baseline period. Four other phases
        reported additional lost hours compared to baseline:{" "}
        <span className="font-bold text-black">
            Cool, Heat, Wait for Acetylator
        </span>{" "}
        and <span className="font-bold text-black">Extrusion</span>.
    </p>,
    <p
        key={3}
        className="bg-white rounded-[11px] border border-insightsBorder p-4 shadow-[0px_3px_1px_#00000021] text-textColor mb-auto  overflow-y-auto"
    >
        The <span className="font-bold text-black">Vac Torque</span> phase
        showed the biggest improvement compared to baseline, with 7.73 lost
        hours saved. 2.6 hours were lost in the last week compared to 10.4 hours
        during the baseline period. The{" "}
        <span className="font-bold text-black">Empty</span> and{" "}
        <span className="font-bold text-black">Transfer</span> phases also
        reported less lost time than baseline for this week.
    </p>,
];

const visualizerInsightData = [
    <p
        key={1}
        className="bg-white rounded-[11px] border border-insightsBorder p-4 shadow-[0px_3px_1px_#00000021] text-textColor mb-auto overflow-y-auto"
    >
        <span className="font-bold text-black">S1000B</span> is empty causing
        delay in <span className="font-bold text-black">CNF</span> and{" "}
        <span className="font-bold text-black">CNA</span> production.
    </p>,
    <p
        key={2}
        className="bg-white rounded-[11px] border border-insightsBorder p-4 shadow-[0px_3px_1px_#00000021] text-textColor mb-auto overflow-y-auto"
    >
        <span className="font-bold text-black">S1000A</span> –{" "}
        <span className="font-bold text-black">CNF</span> storage is full
        causing delay in <span className="font-bold text-black">CNF</span>{" "}
        production.
    </p>,
    <p
        key={3}
        className="bg-white rounded-[11px] border border-insightsBorder p-4 shadow-[0px_3px_1px_#00000021] text-textColor mb-auto  overflow-y-auto"
    >
        <span className="font-bold text-black">S9</span> -{" "}
        <span className="font-bold text-black">F50</span> storage is full
        causing delay in <span className="font-bold text-black">F50</span>{" "}
        production.
    </p>,
    <p
        key={4}
        className="bg-white rounded-[11px] border border-insightsBorder p-4 shadow-[0px_3px_1px_#00000021] text-textColor mb-auto  overflow-y-auto"
    >
        <span className="font-bold text-black">S1000A</span> –{" "}
        <span className="font-bold text-black">CNF</span> storage is low causing
        delay in <span className="font-bold text-black">CNF-based</span> Betain
        production.
    </p>,
];

const visualizerInsightDates = [
    <p key={1} className="text-primary mt-4 self-start">
        January 17th, 2022 00:00
    </p>,
    <p key={2} className="text-primary mt-4 self-start">
        February 26th, 2022 00:00
    </p>,
    <p key={3} className="text-primary mt-4 self-start">
        January 31st, 2022 00:00
    </p>,
    <p key={4} className="text-primary mt-4 self-start">
        June 1st, 2022 00:00
    </p>,
];

export { paretoInsightData, visualizerInsightData, visualizerInsightDates };
