import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../../assets/logo-green.svg";

import { ReactComponent as ProcessFlowIcon } from "../../assets/sideBarIcons/process-flow-icon.svg";
import { ReactComponent as VisualizerIcon } from "../../assets/sideBarIcons/visualizer-icon.svg";
import { ReactComponent as ParetoIcon } from "../../assets/sideBarIcons/pareto-icon.svg";
import { ReactComponent as CycleTimeIcon } from "../../assets/sideBarIcons/cycle-time-icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/sideBarIcons/upload-icon.svg";
import { useContext } from "react";
import { Context } from "../../contexts/Context";

function Sidebar() {
    const { isLoading, isThereError } = useContext(Context);
    const location = useLocation();
    const navBarIcons = [
        [ProcessFlowIcon, "process-flow", "Process Flow"],
        [VisualizerIcon, "visualizer", "Visualizer"],
        [ParetoIcon, "pareto", "Pareto"],
        [CycleTimeIcon, "cycle-time", "Cycle Time"],
        [UploadIcon, "upload", "Upload Files"],
    ].map(([Icon, linkTo, title]) => (
        <li className="w-full" key={title}>
            <NavLink
                to={linkTo}
                key={linkTo}
                className={`block w-full relative after:absolute after:right-0 after:top-[-25%] after:bg-primary after:h-[150%] ${
                    isLoading && "pointer-events-none"
                }`}
                onClick={
                    isThereError &&
                    (() => {
                        window.replace(location.pathname);
                    })
                }
            >
                <Icon title={title} className="h-5 mx-auto" />
            </NavLink>
        </li>
    ));
    return (
        <div className="border-r  h-screen w-min space-y-[52px] pt-4 fixed bg-white z-30">
            <div>
                <NavLink
                    to="visualizer"
                    className={`${isLoading && "pointer-events-none"}`}
                >
                    <LogoIcon
                        className={`h-8 w-[50px] mx-auto ${
                            isLoading && "pointer-events-none"
                        }`}
                    />
                </NavLink>
                <p className="text-center px-2 text-xs">Consultant</p>
            </div>
            <nav className="w-full">
                <ul className="flex flex-col items-center gap-12 h-full">
                    {navBarIcons}
                </ul>
            </nav>
        </div>
    );
}

export default Sidebar;
