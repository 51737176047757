export default function LegendsContainer(props) {
    return (
        <div
            className={`flex gap-14 flex-wrap col-start-2 col-end-4 ${
                props.chartType === "cycleTime" ? "" : "ml-7"
            }`}
        >
            {props.children}
        </div>
    );
}
