import { useRef, useEffect, useState, useContext } from "react";
import * as d3 from "d3";
import { ChartsContext } from "../../contexts/ChartsContext";

const useResizeObserver = (ref) => {
    const [dimensions, setDimensions] = useState(null);
    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => setDimensions(entry.contentRect));
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);

    return dimensions;
};

const VisualizerChart = ({ dateParams }) => {
    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);

    const {
        visualizerData: data,
        // dateParams,
        createTooltip,
        createAssetPhasesAndOpportunityBars,
        createSensorLines,
        sensorData,
        sensorsColors,
        assetSensorList,
        dataEndLimit,
        isSensorShowingOnAsset,
        isPhaseMax,
        graphStartDate,
        graphEndDate,
    } = useContext(ChartsContext);

    const [currentZoomState, setCurrentZoomState] = useState();
    const height = 110 * data.length;

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const padding = 0.2;
        svg.attr("height", height);

        if (!dimensions) return;
        const graphEndTime = graphEndDate
            ? graphEndDate
            : new Date(
                  new Date(dataEndLimit).setDate(
                      new Date(dataEndLimit).getDate() + 1
                  )
              ).setHours(0);
        const graphStartTime = graphStartDate
            ? graphStartDate
            : new Date(graphEndTime).setDate(
                  new Date(graphEndTime).getDate() -
                      (Number.isFinite(dateParams) ? dateParams : 5)
              );

        const xScale = d3
            .scaleTime()
            .domain([graphStartTime, graphEndTime])
            .range([0, dimensions.width]);

        if (currentZoomState) {
            const newXScale = currentZoomState.rescaleX(xScale);
            xScale.domain(newXScale.domain());
        }
        const yScale = d3
            .scaleBand()
            .domain(data.map((asset) => asset.asset))
            .range([height, 0])
            .padding(padding);

        const sensorScaleArray = [];

        if (sensorData && sensorData.length && assetSensorList) {
            for (let assetData of data) {
                sensorScaleArray[data.indexOf(assetData)] = [];

                for (let sensor of assetSensorList[`${assetData.asset}`]) {
                    sensorScaleArray[data.indexOf(assetData)][
                        assetSensorList[`${assetData.asset}`].indexOf(sensor)
                    ] = d3
                        .scaleLinear()
                        .domain(
                            d3.extent(
                                sensorData
                                    .find(
                                        (object) =>
                                            object.sensor_name === sensor
                                    )
                                    .sensor_data.map(
                                        (item) => item.sensor_value
                                    )
                            )
                        )
                        .range([
                            (yScale.bandwidth() +
                                (yScale.bandwidth() / (1 - padding)) *
                                    padding) *
                                (data.length - data.indexOf(assetData)) -
                                (isPhaseMax === true && yScale.bandwidth() / 5),
                            (yScale.bandwidth() / (1 - padding)) * padding +
                                (yScale.bandwidth() +
                                    (yScale.bandwidth() / (1 - padding)) *
                                        padding) *
                                    (data.length -
                                        1 -
                                        data.indexOf(assetData)) +
                                (isPhaseMax === true
                                    ? yScale.bandwidth() / 5
                                    : (yScale.bandwidth() / 5) * 2),
                        ]);
                }
            }
        }

        const xAxis = d3
            .axisBottom(xScale)
            // .ticks(d3.timeHour.every(6))
            .tickFormat(d3.timeFormat("%H:%M"))
            .tickSize(-height);
        const xAxis1 = d3
            .axisBottom(xScale)
            // .ticks(d3.timeDay.every(1))
            .tickFormat(d3.timeFormat("%e %b"));

        const yAxis = d3.axisLeft(yScale).tickSize(-dimensions.width);

        svg.select(".x-axis")
            .attr("transform", `translate(0, ${height})`)
            .call(xAxis)
            .selectAll(".tick text")
            .style("transform", `translate(0, 8px)`);

        svg.select(".x-axis1")
            .attr("transform", `translate(0, ${height + 30})`)
            .call(xAxis1);

            svg.select(".y-axis")
  .call(yAxis)
  .selectAll(".tick text")
  .each(function () {
    var label = d3.select(this);
    var words = label.text().split(/\s+/).reverse();
    var lineHeight = 1.1; // Adjust line height if needed
    var y = label.attr("y");
    var dy = parseFloat(label.attr("dy"));
    var tspan = label.text(null).append("tspan").attr("x", -20).attr("y", y).attr("dy", dy + "em");
    var line = [];
    var word = words.pop();

    while (word) {
      line.push(word);
      tspan.text(line.join(" "));

      if (tspan.node().getComputedTextLength() > 100) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = label.append("tspan").attr("x", -20).attr("y", y).attr("dy", lineHeight + dy + "em").text(word);
      }

      word = words.pop();
    }
  });

          
            

        svg.selectAll(".tick line").style("stroke", "#D5D5D5");
        svg.selectAll(".domain").style("stroke", "#D5D5D5");

        const tooltip = createTooltip("tooltip");
        const lineToolTip = createTooltip("line-tooltip");
        const batchToolTip = createTooltip("batch-tooltip");

        // Two functions that change the tooltip when user hover / leave
        const mouseenter = function (d, phase) {
            tooltip.style("display", "grid");
            tooltip.style("font-size", "20px");
            const tooltipHeadingClasses = "text-[#7a7a7a] ";
            const newHTML = `<span class=${tooltipHeadingClasses}>Product: </span><span>${
                phase.product
            }
                    </span>
                    <span class=${tooltipHeadingClasses}>Phase: </span><span>${
                phase.phase
            }</span>
            <span class=${tooltipHeadingClasses}>Time: </span><span>${
                phase.phase_duration
            }</span>
            <span class=${tooltipHeadingClasses}>Golden Time: </span><span>${
                phase.phase_golden_duration
                    ? phase.phase_golden_duration
                    : "Not Calculated"
            }</span>
            <span class=${tooltipHeadingClasses}>Batch Id: </span><span>${
                phase.batch
            }</span>`;
            tooltip
                .html(newHTML)
                .style("left", `${d3.pointer(d)[0]}px`)

                .style("top", `${d.layerY}px`);
            d3.select(this).style("stroke", "black").style("opacity", 1);
            d.stopPropagation();
        };

        const mouseleave = function (event) {
            tooltip.style("display", "none");
            d3.select(this).style("stroke", "none");
            event.stopPropagation();
        };

        const mouseEnterLine = function (event, data) {
            lineToolTip.style("display", "block");
            lineToolTip
                .html(data[0].sensor_name)
                .style("left", `${d3.pointer(event)[0]}px`)
                .style("top", `${event.layerY}px`);
            d3.select(this).style("stroke-width", 2);
            event.stopPropagation();
        };
        const mouseLeaveLine = function (event, data) {
            lineToolTip.style("display", "none");
            d3.select(this).style("stroke-width", 1);
            event.stopPropagation();
        };

        const mouseEnterBatch = function (event, data) {
            batchToolTip.style("display", "block");
            batchToolTip
                .html(data.batch)
                .style("left", `${d3.pointer(event)[0]}px`)
                .style("top", `${event.layerY}px`);
            d3.select(this).style("stroke-width", 2);
            event.stopPropagation();
        };
        const mouseLeaveBatch = function (event, data) {
            batchToolTip.style("display", "none");
            d3.select(this).style("stroke-width", 1);
            event.stopPropagation();
        };

        svg.selectAll(".clipRect")

            .attr("width", dimensions.width)
            .attr("height", height);

        for (let assetData of data) {
            createAssetPhasesAndOpportunityBars(
                svg,
                `asset-${data.indexOf(assetData)}-phase`,
                `golden-bar-${data.indexOf(assetData)}-asset`,
                `timeLost-bar-${data.indexOf(assetData)}-asset`,
                `asset-${data.indexOf(assetData)}-batch`,
                assetData.process,
                assetData.asset,
                assetData.batch_process,
                xScale,
                yScale,
                mouseenter,
                mouseleave,
                mouseEnterBatch,
                mouseLeaveBatch,
                `asset-${data.indexOf(assetData)}-batchText`
            );
        }

        // let lineSensor0Asset0;
        // if (sensorData) {
        //     lineSensor0Asset0 = d3
        //         .line()
        //         .x((d) => xScale(Date.parse(d.time)))
        //         .y((d) => xScaleSensor0Asset0(d.sensor_value))
        //         .curve(d3.curveMonotoneX);

        //     svg.selectAll(".asset0sensor0line")
        //         .data([
        //             sensorData.find(
        //                 (each_sensor_data) =>
        //                     each_sensor_data.sensor_name === "Sensor 1"
        //             ).sensor_data,
        //         ])
        //         .join("path")
        //         .attr("class", "asset0sensor0line")
        //         .attr("fill", "none")
        //         .attr("stroke", "#0000FF")
        //         .attr("stroke-width", 1)
        //         .attr("d", lineSensor0Asset0)
        //         .attr("clip-path", "url(#clip)");
        // }

        const lineSensorArray = [];
        if (sensorData && sensorData.length && assetSensorList) {
            for (let assetData of data) {
                lineSensorArray[data.indexOf(assetData)] = [];

                for (let sensor of assetSensorList[`${assetData.asset}`]) {
                    lineSensorArray[data.indexOf(assetData)][
                        assetSensorList[`${assetData.asset}`].indexOf(sensor)
                    ] = d3
                        .line()
                        .x((d) => xScale(Date.parse(d.time)))
                        .y((d) => {
                            return sensorScaleArray[data.indexOf(assetData)][
                                assetSensorList[`${assetData.asset}`].indexOf(
                                    sensor
                                )
                            ](d.sensor_value);
                        })
                        .curve(d3.curveMonotoneX);
                }
            }

            //     //
        }
        if (sensorData && sensorData.length && assetSensorList) {
            for (let assetData of data) {
                for (let sensor of assetSensorList[`${assetData.asset}`]) {
                    const singleSensorData = sensorData.find(
                        (each_sensor_data) =>
                            each_sensor_data.sensor_name === sensor
                    ).sensor_data;

                    createSensorLines(
                        isSensorShowingOnAsset[assetData.asset],
                        svg,
                        `asset${data.indexOf(assetData)}sensor${assetSensorList[
                            `${assetData.asset}`
                        ].indexOf(sensor)}line`,
                        // sensorData?.find(
                        //     (each_sensor_data) =>
                        //         each_sensor_data.sensor_name === sensor
                        // ).sensor_data,
                        singleSensorData,
                        lineSensorArray[data.indexOf(assetData)][
                            assetSensorList[`${assetData.asset}`].indexOf(
                                sensor
                            )
                        ],
                        sensor,
                        sensorsColors.find(
                            (item) => item.sensor_name === sensor
                        ).color,
                        assetSensorList[assetData.asset],
                        mouseEnterLine,
                        mouseLeaveLine
                    );
                }
            }
        }

        // zoom
        const zoomBehavior = d3
            .zoom()
            .scaleExtent([1, 5])
            .translateExtent([
                [0, 0],
                [dimensions.width, height],
            ])
            .on("zoom", (event) => {
                const zoomState = event.transform;
                setCurrentZoomState(zoomState);
            });

        svg.call(zoomBehavior);
    }, [
        currentZoomState,
        data,
        dimensions,
        isSensorShowingOnAsset,
        sensorData,
        isPhaseMax,
    ]);

    return (
        <div ref={wrapperRef} className="relative h-full mb-3">
            {data.length > 0 ? (
                <svg ref={svgRef} className="overflow-visible w-full h-full">
                    <g className="x-axis" />
                    <g className="x-axis1" />
                    <g className="y-axis" />

                    <defs className="defs">
                        <clipPath id="clip">
                            <rect className="clipRect"></rect>
                        </clipPath>
                    </defs>
                </svg>
            ) : (
                <div className="h-40 w-full border border-border flex justify-center items-center">
                    Nothing to show
                </div>
            )}

            <div className="tooltip hidden absolute pointer-events-none w-max grid-cols-[max-content_max-content] gap-x-1">
                Hello
            </div>
            <div className="line-tooltip hidden absolute pointer-events-none w-max gap-x-1">
                Hello
            </div>
            <div className="batch-tooltip hidden absolute pointer-events-none w-max gap-x-1">
                Hello
            </div>
        </div>
    );
};

export default VisualizerChart;
