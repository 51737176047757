import CycleTimeUptimeChart from "./CycleTimeUptimeChart";
import CycleTimePhaseChart from "./CycleTimePhaseChart";
import { useContext, useEffect, useState } from "react";
import ToggleButtons from "../../components/ToggleButtons";
import InsightsContainer from "../../components/InsightsContainer";
import { Context } from "../../contexts/Context";
import DateRangeContainer from "../../components/DateRangeContainer";
import YAxisLabel from "../../components/YAxisLabel";
import XAxisLabel from "../../components/XAxisLabel";

function CycleTimeChartsContainer() {
    const [showInsights, setShowInsights] = useState(false);
    const [showDateRange, setShowDateRange] = useState(false);
    const [showBaselineDateRange, setShowBaselineDateRange] = useState(false);

    function toggleInsights() {
        setShowInsights((prevShowInsights) => !prevShowInsights);
        setShowBaselineDateRange(false);
    }
    function toggleDateRange(e) {
        setShowDateRange((prevShowDateRange) => !prevShowDateRange);
        setShowInsights(false);
        setShowBaselineDateRange(false);
        e.stopPropagation();
    }

    // function toggleBaselineDateRange() {
    //     setShowBaselineDateRange(
    //         (prevShowBaselineDateRange) => !prevShowBaselineDateRange
    //     );
    //     setShowInsights(false);
    // }

    useEffect(() => {
        function hideDropDown(e) {
            setShowDateRange(false);
        }
        document.body.addEventListener("click", hideDropDown);
        return () => document.body.removeEventListener("click", hideDropDown);
    }, []);
    const {
        cycleTimePhaseView,
        cycleTimeUptimeView,
        toggleCycleTimeCharts,
        setCycleTimePhaseView,
        setCycleTimeUptimeView,
    } = useContext(Context);

    useEffect(() => {
        setCycleTimePhaseView(false);
        setCycleTimeUptimeView(true);
    }, []);

    return (
        <>
            <YAxisLabel
                labelText={
                    cycleTimeUptimeView
                        ? "Slow Running Time Loss (% of Uptime)"
                        : "Weekly Time Loss for Phase (Hrs)"
                }
            />
            <div className="flex flex-col gap-12">
                <p className="absolute top-20 left-0">
                    {cycleTimeUptimeView
                        ? "Cycle time slow running loss (as % of uptime) - Polymerizers"
                        : "Weekly Lost Time in Phase Heatup - Polymerizers"}
                </p>
                <div className="flex gap-3 self-end">
                    <ToggleButtons
                        firstOption={{
                            view: cycleTimeUptimeView,
                            text: "% of Uptime",
                        }}
                        secondOption={{
                            view: cycleTimePhaseView,
                            text: "By Phase",
                        }}
                        handleClick={toggleCycleTimeCharts}
                    />
                </div>
                {cycleTimeUptimeView && <CycleTimeUptimeChart />}
                {cycleTimePhaseView && <CycleTimePhaseChart />}
                <XAxisLabel labelText="Weeks Commencing" />
            </div>
            <div className="flex justify-end gap-4 relative">
                <DateRangeContainer
                    toggleDateRange={toggleDateRange}
                    showDateRange={showDateRange}
                    chartType="Cycletime"
                />
                <InsightsContainer
                    insightData={[]}
                    insightDates={[]}
                    toggleInsights={toggleInsights}
                    showInsights={showInsights}
                />
            </div>
        </>
    );
}

export default CycleTimeChartsContainer;
