import Button from "./Button";
import BaselineDatePicker from "./BaselineDatePicker";
import { useContext } from "react";
import { ChartsContext } from "../contexts/ChartsContext";

export default function SetBaselineContainer({
    showBaselineDateRange,
    toggleBaselineDateRange,
    setShowBaselineDateRange,

    BaselineIcon,
    menuRef,
}) {
    const { baselineStartDate, baselineEndDate } = useContext(ChartsContext);

    return (
        <div className="relative flex flex-col gap-4">
            <Button
                showButtonDropDown={showBaselineDateRange}
                toggleShowHide={toggleBaselineDateRange}
                Icon={BaselineIcon}
                buttonText="Set Baseline"
            />
            {showBaselineDateRange &&
            !isNaN(baselineStartDate) &&
            !isNaN(baselineEndDate) ? (
                <BaselineDatePicker
                    toggleBaselineDateRange={toggleBaselineDateRange}
                    setShowBaselineDateRange={setShowBaselineDateRange}
                />
            ) : (
                showBaselineDateRange && (
                    <p
                        ref={menuRef}
                        className="absolute text-[10px] text-textColor bg-white border border-border rounded-md top-[120%] z-10 p-2"
                    >
                        No phase data exists to set baseline, please upload file
                        to continue
                    </p>
                )
            )}
        </div>
    );
}
