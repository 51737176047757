import React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
    check_file_format_phase,
    check_file_format_sensor,
    save_file_phase,
    save_file_sensor,
    check_file_progress,
} from "../../services/api-calls";
import CSVFileIcon from "../../assets/uploadFileIcons/csv-file-icon.png";
import { Context } from "../../contexts/Context";
import Loader from "../../components/loadingComponents/Loader";
import { ReactComponent as CorrectFile } from "../../assets/uploadFileIcons/correct-tick.svg";
import { ReactComponent as ErrorFile } from "../../assets/uploadFileIcons/error.svg";
import { ReactComponent as UpwardArrow } from "../../assets/insightsIcons/upward-arrow.svg";
import { ReactComponent as DownwardArrow } from "../../assets/insightsIcons/downward-arrow.svg";
import { ReactComponent as Tick } from "../../assets/uploadFileIcons/tick.svg";
import { ReactComponent as Cross } from "../../assets/insightsIcons/cancel-cross.svg";

export default function UploadFileModal(props) {
    const { isLoading, setIsLoading, checkProgress } = useContext(Context);
    const { setUploadModal } = props;
    const [selectedFile, setSelectedFile] = useState(null);
    const [isCheckingFileFormat, setIsCheckingFileFormat] = useState(false);
    const [isCorrectFileFormat, setIsCorrectFileFormat] = useState(false);
    const [isIncorrectFileFormat, setIsIncorrectFileFormat] = useState(false);
    const [isFileSaving, setIsFileSaving] = useState(false);
    const [fileUploadedSuccessfully, setFileUploadedSuccessfully] =
        useState(false);
    const [fileDescription, setFileDescription] = useState("");
    const [showFileOptions, setShowFileOptions] = useState(false);
    const [dataType, setDataType] = useState("Phase Data");
    const [errorMessage, setErrorMessage] = useState(
        "File Error. Please upload correctly formatted file"
    );

    function toggleFileOptions(e) {
        setShowFileOptions((prevShowFileOptions) => !prevShowFileOptions);
        e.stopPropagation();
    }

    let menuRef = useRef();
    useEffect(() => {
        function hideDropDown(e) {
            if (!menuRef.current?.contains(e.target)) {
                setShowFileOptions(false);
            }
        }
        window.addEventListener("click", hideDropDown);
        return () => window.removeEventListener("click", hideDropDown);
    }, []);

    useEffect(() => {
        if (selectedFile) {
            const upload = async () => {
                try {
                    setIsLoading(true);
                    const formData = new FormData();
                    formData.append("file", selectedFile);
                    if (dataType === "Phase Data") {
                        const a = await check_file_format_phase(formData);
                    } else if (dataType === "Sensor Data") {
                        await check_file_format_sensor(formData);
                    }
                    setIsCheckingFileFormat(false);
                    setIsCorrectFileFormat(true);
                    setIsIncorrectFileFormat(false);
                    setIsLoading(false);
                } catch (e) {
                    console.log(e);
                    setIsCheckingFileFormat(false);
                    setIsCorrectFileFormat(false);
                    setIsIncorrectFileFormat(true);
                    setIsLoading(false);
                    if (e.response.data.code === 403) {
                        setErrorMessage(e.response.data.message);
                    }
                }
            };
            upload();
        }
    }, [selectedFile]);

    useEffect(() => {
        if (isFileSaving) {
            const saveFile = async () => {
                try {
                    const formData = new FormData();
                    formData.append("file", selectedFile);
                    formData.append("file_description", fileDescription);
                    formData.append("file_size", selectedFile.size);
                    const response = await check_file_progress();
                    if (
                        response.code === 200 &&
                        !response.data.upload_in_progress &&
                        !response.data.sensor_upload_in_progress
                    ) {
                        let response;
                        if (dataType === "Phase Data") {
                            response = await save_file_phase(formData);
                        } else if (dataType === "Sensor Data") {
                            response = await save_file_sensor(formData);
                        }

                        if (response.code === 200) {
                            checkProgress();
                            setIsFileSaving(false);
                            setFileUploadedSuccessfully(true);
                            setIsLoading(false);
                            setUploadModal(false);
                            // async function checkFileProgress() {
                            //     try {
                            //         const response =
                            //             await check_file_progress();
                            //         if (
                            //             !response.data.upload_in_progress &&
                            //             !response.data.sensor_upload_in_progress
                            //         ) {
                            //             // setIsFileSaving(false);
                            //             // setFileUploadedSuccessfully(true);
                            //             // setIsLoading(false);
                            //             // setUploadModal(false);
                            //             clearInterval(intervalID);
                            //             clearTimeout(timeoutID);
                            //         }
                            //     } catch (e) {
                            //         // setIsFileSaving(false);
                            //         // setFileUploadedSuccessfully(true);
                            //         // setIsLoading(false);
                            //         console.log(e);
                            //     }
                            // }
                            // const intervalID = setInterval(
                            //     checkFileProgress,
                            //     3000
                            // );

                            // const timeoutID = setTimeout(() => {
                            //     clearInterval(intervalID);
                            // }, 300000);
                        } else {
                            setIsFileSaving(false);
                            setFileUploadedSuccessfully(false);
                            setIsLoading(false);
                        }
                    } else if (
                        response.data.upload_in_progress ||
                        response.data.sensor_upload_in_progress
                    ) {
                        setIsFileSaving(false);
                        setFileUploadedSuccessfully(false);
                        setIsLoading(false);
                    }
                } catch (e) {
                    setIsFileSaving(false);
                    setFileUploadedSuccessfully(false);
                    setIsLoading(false);
                }
            };
            saveFile();
        }
    }, [isFileSaving]);

    const onFileChange = (e) => {
        const { files } = e.target;

        if (files && files.length) {
            setSelectedFile(files[0]);
        } else {
            setSelectedFile(null);
        }
        setIsLoading(true);
        setIsCheckingFileFormat(true);
        setIsCorrectFileFormat(false);
        setIsIncorrectFileFormat(false);
        setFileUploadedSuccessfully(false);
    };
    const saveFile = () => {
        setIsLoading(true);
        setIsFileSaving(true);
        setFileUploadedSuccessfully(false);
        setIsCorrectFileFormat(false);
    };

    const deleteFile = () => {
        setSelectedFile(null);
        setIsFileSaving(false);
        setIsCorrectFileFormat(false);
        setIsCheckingFileFormat(false);
    };

    return (
        <div className="flex justify-center items-center fixed top-10 left-10 w-full h-full">
            <div className="w-full h-full absolute bg-gray-400 z-10 opacity-80 flex justify-center items-center"></div>
            <div className="absolute z-20 opacity-100 bg-white shadow-xl rounded-md p-5 px-8 flex flex-col items-center gap-6 max-h-[80vh] overflow-y-auto">
                <div className="flex justify-end w-full">
                    <Cross
                        className=" w-4 h-4 cursor-pointer"
                        onClick={() => {
                            setUploadModal(false);
                        }}
                    />
                </div>
                <div className="flex flex-col justify-center items-center gap-2">
                    <div
                        className={`flex flex-col items-center gap-3 ${
                            isLoading && "cursor-wait"
                        }`}
                    >
                        <div className="flex flex-col gap-8 items-center">
                            <div className="flex flex-col gap-4 relative min-w-[200px]">
                                <div
                                    className="flex items-center justify-between gap-8 border border-border p-1 px-4 rounded-md cursor-pointer"
                                    onClick={toggleFileOptions}
                                >
                                    <p>{dataType}</p>
                                    {showFileOptions ? (
                                        <UpwardArrow />
                                    ) : (
                                        <DownwardArrow />
                                    )}
                                </div>
                                {showFileOptions && (
                                    <div
                                        ref={menuRef}
                                        className="border border-border rounded-md overflow-hidden w-full absolute top-12 py-2 bg-white"
                                    >
                                        {["Phase Data", "Sensor Data"].map(
                                            (fileType) => (
                                                <label
                                                    htmlFor={fileType}
                                                    className="block py-1 px-3 group hover:bg-tableBackground2 cursor-pointer"
                                                >
                                                    <p className="text-textColor text-xs whitespace-nowrap group-hover:font-bold">
                                                        {fileType}
                                                    </p>
                                                    <input
                                                        type="radio"
                                                        name={fileType}
                                                        value={fileType}
                                                        checked={
                                                            dataType ===
                                                            fileType
                                                        }
                                                        className="hidden"
                                                        id={fileType}
                                                        onChange={(e) => {
                                                            setDataType(
                                                                e.target.value
                                                            );
                                                            setSelectedFile(
                                                                null
                                                            );
                                                        }}
                                                        onClick={() =>
                                                            setShowFileOptions(
                                                                false
                                                            )
                                                        }
                                                    />
                                                </label>
                                            )
                                        )}
                                    </div>
                                )}
                                <div className="italic text-sm">
                                    <p>
                                        *{dataType} file should contain the
                                        following columns
                                    </p>
                                    {dataType === "Phase Data" ? (
                                        <div>
                                            {[
                                                ["Asset", "string"],
                                                ["Batch", "string"],
                                                ["Product", "string"],
                                                ["Phase", "string"],
                                                ["Phase Start Time", "*"],
                                                ["Phase End Time", "*"],
                                            ].map(([column, format]) => (
                                                <div
                                                    className="flex gap-1 items-center pl-3"
                                                    key={column}
                                                >
                                                    <Tick className="w-2" />
                                                    <p>
                                                        <b>{column}</b> -{" "}
                                                        {format}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="flex gap-1 items-center pl-3">
                                                <Tick className="w-2" />
                                                <p>
                                                    <b>Time*</b>
                                                </p>
                                            </div>
                                            <div className="flex gap-1 items-center pl-3">
                                                <Tick className="w-2" />
                                                <p>
                                                    One or more columns of
                                                    sensor names
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <p className="italic border border-border p-5 rounded-md text-xs text-center w-72">
                                *All time columns can be in{" "}
                                <span className="font-bold">
                                    UNIX, datetime string, or Excel datetime
                                    number
                                </span>{" "}
                                format
                            </p>
                            <label
                                htmlFor="dropzone-file"
                                className={`flex flex-col items-center justify-center w-full py-12 px-28 border-2 border-gray-300 border-dashed rounded-lg bg-gray-50  ${
                                    isLoading
                                        ? "pointer-events-none"
                                        : "cursor-pointer hover:bg-gray-100"
                                }`}
                            >
                                <div className="flex flex-col items-center justify-center">
                                    <p className="text-lg text-black">
                                        <span className="text-blueColor">
                                            Choose File
                                        </span>{" "}
                                        to upload
                                    </p>
                                    <p className="text-sm text-textColor2">
                                        Supported Format: CSV, XLSX
                                    </p>
                                </div>
                                <input
                                    type="file"
                                    className={`hidden ${
                                        isLoading &&
                                        "pointer-events-none cursor-wait"
                                    }`}
                                    id="dropzone-file"
                                    accept=".csv, .xlsx"
                                    onChange={onFileChange}
                                    onClick={(event) =>
                                        (event.target.value = null)
                                    }
                                />
                            </label>
                        </div>
                        {selectedFile && (
                            <aside className="text-xs w-full space-y-4">
                                <div className="flex items-center gap-3">
                                    {isLoading ? (
                                        <Loader />
                                    ) : isCorrectFileFormat ? (
                                        <CorrectFile />
                                    ) : isIncorrectFileFormat ? (
                                        <ErrorFile />
                                    ) : fileUploadedSuccessfully ? (
                                        <CorrectFile />
                                    ) : (
                                        <ErrorFile />
                                    )}
                                    <p className="text-textColor text-sm">
                                        {isFileSaving ? (
                                            "Uploading file...this may take a few minutes"
                                        ) : fileUploadedSuccessfully ? (
                                            <span>
                                                File uploaded successfully. Go
                                                to{" "}
                                                <NavLink
                                                    to="/dashboard/visualizer"
                                                    className="text-blueColor"
                                                >
                                                    Visualizer
                                                </NavLink>{" "}
                                                to view changes
                                            </span>
                                        ) : isCheckingFileFormat ? (
                                            "Checking file format..."
                                        ) : isCorrectFileFormat ? (
                                            "File format is correct. Click Save to upload file"
                                        ) : isIncorrectFileFormat ? (
                                            errorMessage
                                        ) : (
                                            "Couldn't upload file. It could be that data is updating elsewhere. Please try again later."
                                        )}
                                    </p>
                                </div>

                                <div className="p-5 border border-border rounded-[10px] flex flex-col gap-10">
                                    <div className="flex gap-4">
                                        <img
                                            src={CSVFileIcon}
                                            className="w-8"
                                            alt="File Icon"
                                        />
                                        <div>
                                            <p>{selectedFile.name}</p>
                                            <p className="text-textColor2">
                                                {selectedFile.size / 1024 >=
                                                1024 ? (
                                                    <span>
                                                        {(
                                                            selectedFile.size /
                                                            1024 /
                                                            1024
                                                        ).toFixed(2)}{" "}
                                                        MB
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {(
                                                            selectedFile.size /
                                                            1024
                                                        ).toFixed(2)}{" "}
                                                        KB
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    {!isCheckingFileFormat &&
                                        !isIncorrectFileFormat &&
                                        selectedFile &&
                                        !isFileSaving &&
                                        !fileUploadedSuccessfully && (
                                            <textarea
                                                placeholder="Add Description (Optional)"
                                                className="w-full"
                                                name="fileDescription"
                                                value={fileDescription}
                                                onChange={(e) =>
                                                    setFileDescription(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        )}
                                </div>
                            </aside>
                        )}
                        {!isCheckingFileFormat &&
                            !isIncorrectFileFormat &&
                            selectedFile &&
                            !fileUploadedSuccessfully && (
                                <div
                                    className={`space-x-4 ${
                                        isLoading && "cursor-wait"
                                    }`}
                                >
                                    <button
                                        className={`bg-transparent py-2 px-6 rounded-md border border-border ${
                                            isLoading && "pointer-events-none"
                                        }`}
                                        onClick={deleteFile}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className={`bg-primary text-white py-2 px-6 rounded-md ${
                                            isLoading && "pointer-events-none"
                                        }`}
                                        onClick={saveFile}
                                    >
                                        Save
                                    </button>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
}
