import { useEffect, useState, useContext } from "react";
import FileListTable from "./FileListTable";
import UploadFileModal from "./UploadFileModal";
import { ReactComponent as SearchIcon } from "../../assets/insightsIcons/searchIcon.svg";
import { get_file_list } from "../../services/api-calls";
import { Context } from "../../contexts/Context";
import toast, { Toaster } from "react-hot-toast";

// import convertDate from "../../helpers/convertDate";

export default function Upload() {
    const [openUploadModal, setUploadModal] = useState(false);
    const [isActiveFiles, setIsActiveFile] = useState(false);
    const [fileList, setFileList] = useState(null);
    const [fileCount, setFileCount] = useState({ allFiles: 0, activeFiles: 0 });
    const [allFiles, setAllfiles] = useState(null);
    const [activeFiles, setActivefiles] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [timer, setTimer] = useState(0);
    const { isLoading, setIsLoading } = useContext(Context);

    const isActiveCard = (isTrue) => {
        if (isTrue === true) {
            setFileList(activeFiles);
            setIsActiveFile(isTrue);
        } else {
            setIsActiveFile(isTrue);
            setFileList(allFiles);
        }
    };
    useEffect(() => {
        getFiles();
    }, [searchString]);
    const getFiles = async () => {
        try {
            setIsLoading(true);
            const response = await get_file_list(searchString);
            let fileData = response.data.files_data;
            setFileList(fileData);
            setAllfiles(fileData);
            setFileCount({
                allFiles: response?.data?.total_files_count,
                activeFiles: response?.data?.active_files_count,
            });
            let activeFiles = fileData?.filter(
                (res) => res?.is_active === true
            );
            setIsLoading(false);
            setActivefiles(activeFiles);
        } catch (error) {
            setIsLoading(false);

            console.log(error);
        }
    };
    const handleChange = (e) => {
        if (timer) {
            window.clearTimeout(timer);
        }
        let time = setTimeout(() => {
            setSearchString(e.target.value);
        }, 800);
        setTimer(time);
    };
    return (
        <>
            <div className=" w-full h-full">
                <div className=" flex justify-between w-full">
                    <div className="flex">
                        <div
                            className={` opacity-100 rounded-md p-2 w-40 cursor-pointer ${
                                isActiveFiles === false
                                    ? "bg-[#D0EDC8]"
                                    : "shadow-xl"
                            }`}
                            onClick={() => isActiveCard(false)}
                        >
                            <p className=" text-md">All files</p>
                            <p className=" text-end text-2xl text-green-700 mb-0">
                                {fileCount.allFiles}
                            </p>
                        </div>
                        <div
                            className={` ml-7 opacity-100 rounded-md p-2 w-40 cursor-pointer ${
                                isActiveFiles === true
                                    ? "bg-[#D0EDC8]"
                                    : "shadow-xl"
                            }`}
                            onClick={() => isActiveCard(true)}
                        >
                            <p className=" text-md  ">Active files</p>
                            <p className=" text-end text-2xl text-green-700 mb-0">
                                {fileCount.activeFiles}
                            </p>
                        </div>
                    </div>
                    <div className="flex w-full justify-end">
                        {/* <input placeholder="search" className=" border-solid border-2 border-gray-200 rounded-md indent-3 focus-visible:outline-none w-1/2 h-10" /> */}
                        <label
                            htmlFor="email"
                            className="relative border-solid border-2 border-gray-200 rounded-md indent-3 focus-visible:outline-none w-full max-w-xs ml-2 h-10 focus-within:text-gray-600 block"
                        >
                            <SearchIcon className="pointer-events-none w-6 h-6 absolute top-2/4 transform -translate-y-1/2 left-3" />
                            <input
                                placeholder="search"
                                className="form-input mt-[6px] w-[90%] indent-8 outline-none bg-transparent"
                                onChange={handleChange}
                            />
                        </label>
                        <button
                            className=" bg-green-700 text-white p-2 px-8 rounded-md ml-4 h-10"
                            onClick={() => {
                                setUploadModal(true);
                            }}
                        >
                            Upload File
                        </button>
                    </div>
                </div>
                <FileListTable
                    fileList={fileList}
                    setFileList={setFileList}
                    getFiles={getFiles}
                />
                <Toaster />
                {openUploadModal === true ? (
                    <UploadFileModal setUploadModal={setUploadModal} />
                ) : (
                    ""
                )}
            </div>
        </>
    );
}
