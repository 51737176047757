import InsightsContainer from "../../components/InsightsContainer";
import VisualizerChart from "./VisualizerChart";
import DateRangeContainer from "../../components/DateRangeContainer";
import GoldenTimeList from "./GoldenTimeList";
import { useState, useEffect, useRef, useContext } from "react";
import YAxisLabel from "../../components/YAxisLabel";
import XAxisLabel from "../../components/XAxisLabel";
import { ReactComponent as BaselineIcon } from "../../assets/baselineIcons/baseline-icon.svg";
import { ReactComponent as FilterIcon } from "../../assets/sensorAssets/filter-icon.svg";
import { ReactComponent as GoldenTimeIcon } from "../../assets/golden-time-icon.svg";
import SetBaselineContainer from "../../components/SetBaselineContainer";
import AllAssetToggle from "../../components/AllAssetToggle";
import SensorOnAssetToggle from "./SensorOnAssetToggle";
import { ChartsContext } from "../../contexts/ChartsContext";
import FiltersContainer from "../../components/FiltersContainer";
import {
    visualizerInsightData,
    visualizerInsightDates,
} from "../../data/insightsData";
import ToggleButtons from "../../components/ToggleButtons";
import Button from "../../components/Button";

function VisualizerChartContainer() {
    const { baselineStartDate, baselineEndDate, setIsPhaseMax, isPhaseMax } =
        useContext(ChartsContext);
    const [showInsights, setShowInsights] = useState(false);
    const [showDateRange, setShowDateRange] = useState(false);
    const [showBaselineDateRange, setShowBaselineDateRange] = useState(false);
    const [showSensorToggleMenu, setShowSensorToggleMenu] = useState(false);
    const [showAllAssetMenu, SetShowAllAssetMenu] = useState(false);
    const [showFilteredAssets, setShowFilteredAssets] = useState(false);
    const [showCustomRangeMenu, setShowCustomRangeMenu] = useState(false);
    const [showGoldenTimeList, setShowGoldenTimeList] = useState(false);
    const [dateParams, setDateParams] = useState(5);

    function toggleInsights() {
        setShowInsights((prevShowInsights) => !prevShowInsights);
        setShowDateRange(false);
        setShowBaselineDateRange(false);
        setShowSensorToggleMenu(false);
        setShowCustomRangeMenu(false);
        SetShowAllAssetMenu(false);
        setShowFilteredAssets(false);
        setShowGoldenTimeList(false);
    }
    function toggleFilteredAssets(e) {
        setShowFilteredAssets(
            (prevShowFilteredAssets) => !prevShowFilteredAssets
        );
        setShowInsights(false);
        setShowDateRange(false);
        setShowBaselineDateRange(false);
        setShowSensorToggleMenu(false);
        setShowCustomRangeMenu(false);
        SetShowAllAssetMenu(false);
        setShowGoldenTimeList(false);
        // e.stopPropagation();
    }
    function toggleDateRange(e) {
        setShowDateRange((prevShowDateRange) => !prevShowDateRange);
        setShowInsights(false);
        setShowBaselineDateRange(false);
        setShowSensorToggleMenu(false);
        setShowCustomRangeMenu(false);
        setShowFilteredAssets(false);
        SetShowAllAssetMenu(false);
        setShowGoldenTimeList(false);
        e.stopPropagation();
    }
    function toggleBaselineDateRange(e) {
        setShowBaselineDateRange(
            (prevShowBaselineDateRange) => !prevShowBaselineDateRange
        );
        setShowFilteredAssets(false);
        setShowDateRange(false);
        setShowInsights(false);
        setShowSensorToggleMenu(false);
        setShowCustomRangeMenu(false);
        SetShowAllAssetMenu(false);
        setShowGoldenTimeList(false);
        if (isNaN(baselineStartDate) || isNaN(baselineEndDate)) {
            e.stopPropagation();
        }
    }

    function toggleShowSensorMenu(e) {
        setShowSensorToggleMenu(
            (prevShowSensorToggleMenu) => !prevShowSensorToggleMenu
        );
        setShowDateRange(false);
        setShowInsights(false);
        setShowBaselineDateRange(false);
        setShowCustomRangeMenu(false);
        SetShowAllAssetMenu(false);
        setShowGoldenTimeList(false);
        setShowFilteredAssets(false);

        e.stopPropagation();
    }
    function toggleAllAssetMenu(e) {
        SetShowAllAssetMenu((prevState) => !prevState);
        // debugger
        setShowDateRange(false);
        setShowInsights(false);
        setShowSensorToggleMenu(false);
        setShowFilteredAssets(false);
        setShowGoldenTimeList(false);
        setShowBaselineDateRange(false);
        setShowCustomRangeMenu(false);

        // if (e) {
        // e.stopPropagation();
        // }
    }

    function toggleShowGoldenTimeList() {
        setShowGoldenTimeList((prevState) => !prevState);
        setShowDateRange(false);
        setShowInsights(false);
        setShowBaselineDateRange(false);
        setShowSensorToggleMenu(false);
        SetShowAllAssetMenu(false);
        setShowFilteredAssets(false);
        setShowCustomRangeMenu(false);
    }

    let menuRef = useRef();
    useEffect(() => {
        function hideDropDown(e) {
            if (!menuRef.current?.contains(e.target)) {
                setShowDateRange(false);
                setShowSensorToggleMenu(false);
                // SetShowAllAssetMenu(false)
                if (isNaN(baselineStartDate) || isNaN(baselineEndDate)) {
                    setShowBaselineDateRange(false);
                }
            }
        }

        window.addEventListener("click", hideDropDown);

        return () => window.removeEventListener("click", hideDropDown);
    }, []);

    return (
        <>
            <YAxisLabel labelText="Assets" />

            <div className="flex flex-col gap-3 ml-7">
                <div className="flex items-center gap-3 self-end">
                    <p className="text-sm">
                        Baseline Start Date:{" "}
                        <span className="text-textColor">
                            {baselineStartDate?.toLocaleString("en-US", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                            })}
                        </span>
                    </p>
                    <p className="text-sm">
                        Baseline End Date:{" "}
                        <span className="text-textColor">
                            {baselineEndDate?.toLocaleString("en-US", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                            })}
                        </span>
                    </p>
                    <SetBaselineContainer
                        showBaselineDateRange={showBaselineDateRange}
                        toggleBaselineDateRange={toggleBaselineDateRange}
                        setShowBaselineDateRange={setShowBaselineDateRange}
                        BaselineIcon={BaselineIcon}
                        // menuRef={menuRef}
                    />
                </div>
                <div className="flex gap-4 self-end">
                    <div className="flex">
                        <ToggleButtons
                            firstOption={{
                                view: isPhaseMax,
                                text: "Phase View",
                            }}
                            secondOption={{
                                view: !isPhaseMax,
                                text: "Baseline View",
                            }}
                            handleClick={() => setIsPhaseMax(!isPhaseMax)}
                        />
                    </div>
                    <Button
                        showButtonDropDown={showGoldenTimeList}
                        toggleShowHide={toggleShowGoldenTimeList}
                        Icon={GoldenTimeIcon}
                        buttonText="Golden Time"
                    />
                    <SensorOnAssetToggle
                        showSensorToggleMenu={showSensorToggleMenu}
                        toggleShowSensorMenu={toggleShowSensorMenu}
                        FilterIcon={FilterIcon}
                        menuRef={menuRef}
                    />
                </div>

                <VisualizerChart dateParams={dateParams} />
                <XAxisLabel labelText="Time" chartType="Visualizer" />
            </div>

            <div className="space-y-2 relative">
                <div className="flex justify-between gap-2 relative">
                    <DateRangeContainer
                        toggleDateRange={toggleDateRange}
                        showDateRange={showDateRange}
                        setShowDateRange={setShowDateRange}
                        showCustomRangeMenu={showCustomRangeMenu}
                        setShowCustomRangeMenu={setShowCustomRangeMenu}
                        dateParams={dateParams}
                        setDateParams={setDateParams}
                        menuRef={menuRef}
                        chartType="Visualizer"
                    />
                    <InsightsContainer
                        insightData={visualizerInsightData}
                        insightDates={visualizerInsightDates}
                        toggleInsights={toggleInsights}
                        showInsights={showInsights}
                    />
                </div>
                <div className="flex justify-between gap-2 relative">
                    <AllAssetToggle
                        toggleAllAssetMenu={toggleAllAssetMenu}
                        showAllAssetMenu={showAllAssetMenu}
                        SetShowAllAssetMenu={SetShowAllAssetMenu}

                        // menuRef={menuRef}
                    />
                    <FiltersContainer
                        FilterIcon={FilterIcon}
                        toggleAssets={toggleFilteredAssets}
                        showAssets={showFilteredAssets}
                    />
                </div>
                {showGoldenTimeList && (
                    <GoldenTimeList
                        toggleShowGoldenTimeList={toggleShowGoldenTimeList}
                    />
                )}
            </div>
        </>
    );
}
export default VisualizerChartContainer;
