
const convertDate = (oldDate) => {
    const thisDate = new Date(oldDate);
    const offset = (thisDate.getTimezoneOffset() / 60);
    const a = thisDate.getHours() - offset
    const hourResponse = fetchHours(thisDate.getHours() - offset );
    const month = fetchMonth(thisDate.getMonth());
    const hour = hourResponse.hour;
    const ampm = hourResponse.ampm;
    let date;
    if(a>24){
       date = thisDate.getDate()+1;
    }else if(a>=0 && a<=24){
      date = thisDate.getDate();
    }else{
      date= thisDate.getDate()-1;
    }
    const year = thisDate.getFullYear();
    const minutes = thisDate.getMinutes() < 10 ? `0${thisDate.getMinutes()}` : thisDate.getMinutes();
    return `${month}/${date}/${year} ${hour}:${minutes} ${ampm}`
  }
  
  const fetchHours = (hours) => {
    if(hours>24){
      hours= hours-24;
    }
    const response = {
      hour: '',
      ampm: '',
    }
    if (hours >= 12) {
      response.ampm = 'pm'
      switch (hours) {
        case 12:
         response.hour = '12'
         break;
        case 13:
          response.hour = '1'
          break;
        case 14:
          response.hour = '2'
          break;
        case 15:
          response.hour = '3'
          break;
        case 16:
          response.hour = '4'
          break;
        case 17:
          response.hour = '5'
          break;
        case 18:
          response.hour = '6'
          break;
        case 19:
          response.hour = '7'
          break;
        case 20:
          response.hour = '8'
          break;
        case 21:
          response.hour = '9'
          break;
        case 22:
          response.hour = '10'
          break;
        case 23:
          response.hour = '11'
          break;
        default:
          response.hour = '0'
      }
    } else {
      response.hour = `${hours}`
      response.ampm = 'am'
    }
    return response;
  }
  
  const fetchMonth = (month) => {
    let response = '';
    switch (month) {
      case 0:
        response = 'Jan';
        break;
      case 1:
        response = 'Feb';
        break;
      case 2:
        response = 'Mar';
        break;
      case 3:
        response = 'Apr';
        break;
      case 4:
        response = 'May';
        break;
      case 5:
        response = 'Jun';
        break;
      case 6:
        response = 'Jul';
        break;
      case 7:
        response = 'Aug';
        break;
      case 8:
        response = 'Sep';
        break;
      case 9:
        response = 'Oct';
        break;
      case 10:
        response = 'Nov';
        break;
      case 11:
        response = 'Dec';
        break;
      default:
        response = 'Error'
    }
    return response;
  }
  
  export default convertDate;
  