import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ProcessFlowChart from "./pages/Process Flow";
import ContentContainer from "./components/ContentContainer";
import LegendsContainer from "./components/legends/LegendsContainer";
import ParetoChartsContainer from "./pages/Pareto/ParetoChartsContainer";
import ParetoTable from "./pages/Pareto/ParetoTable";
import CycleTimeChartsContainer from "./pages/Cycle Time/CycleTimeChartsContainer";
import VisualizerChartContainer from "./pages/Visualizer/VisualizerChartContainer";
import LegendsIdentifier from "./components/legends/LegendsIdentifier";
import {
    opportunityLevelColors,
    cycleTimeUptimeColors,
    lineIdentifierUptimeData,
    cycleTimePhaseColor,
} from "./data/colors-data";
import { useContext } from "react";
import { Context } from "./contexts/Context";
import Upload from "./pages/Upload";
import { ChartsContext } from "./contexts/ChartsContext";

function App() {
    const { cycleTimeUptimeView, config, isThereError } = useContext(Context);

    return (
        <>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/dashboard" element={<Dashboard />}>
                    <Route
                        path="upload"
                        element={
                            <ContentContainer>
                                <Upload />
                            </ContentContainer>
                        }
                    />
                    <Route
                        path="process-flow"
                        element={
                            <ContentContainer>
                                <div className="flex justify-center items-center">
                                    <ProcessFlowChart />
                                </div>
                            </ContentContainer>
                        }
                    />
                    <Route
                        path="visualizer"
                        element={
                            <ContentContainer>
                                <div
                                    className={`${
                                        isThereError
                                            ? "flex justify-center items-center"
                                            : "grid grid-cols-[minmax(2%,5%)_minmax(60%,65%)_minmax(25%,30%)] gap-10"
                                    }`}
                                >
                                    <VisualizerChartContainer />
                                    <LegendsContainer chartType="visualizer">
                                        {[
                                            [config?.colours_data, "Phases"],
                                            [
                                                opportunityLevelColors,
                                                "Opportunity Level",
                                            ],
                                        ].map(([colorData, heading]) => (
                                            <LegendsIdentifier
                                                legendsData={colorData}
                                                heading={heading}
                                                legendType={{ color: true }}
                                                key={heading}
                                            />
                                        ))}
                                    </LegendsContainer>
                                </div>
                            </ContentContainer>
                        }
                    />
                    <Route
                        path="pareto"
                        element={
                            <ContentContainer heading="Pareto">
                                <div
                                    className={`${
                                        isThereError
                                            ? "flex justify-center items-center"
                                            : "grid grid-cols-[minmax(2%,5%)_minmax(60%,65%)_minmax(25%,30%)] gap-10"
                                    }`}
                                >
                                    <ParetoChartsContainer />
                                    <ParetoTable />
                                </div>
                            </ContentContainer>
                        }
                    />
                    <Route
                        path="cycle-time"
                        element={
                            <ContentContainer>
                                <div
                                    className={`${
                                        isThereError
                                            ? "flex justify-center items-center"
                                            : "grid grid-cols-[minmax(2%,5%)_minmax(60%,65%)_minmax(25%,30%)] gap-10"
                                    }`}
                                >
                                    <CycleTimeChartsContainer />
                                    <LegendsContainer chartType="cycleTime">
                                        <LegendsIdentifier
                                            legendsData={
                                                cycleTimeUptimeView
                                                    ? cycleTimeUptimeColors
                                                    : cycleTimePhaseColor
                                            }
                                            heading={
                                                cycleTimeUptimeView
                                                    ? "Phases"
                                                    : "Phase"
                                            }
                                            legendType={{ color: true }}
                                        />
                                        <LegendsIdentifier
                                            legendsData={
                                                cycleTimeUptimeView
                                                    ? lineIdentifierUptimeData
                                                    : lineIdentifierUptimeData.filter(
                                                          (item) =>
                                                              item.name !==
                                                              "Target 15% ZLE Improvement"
                                                      )
                                            }
                                            heading="Lines"
                                            legendType={{ line: true }}
                                        />
                                    </LegendsContainer>
                                </div>
                            </ContentContainer>
                        }
                    />
                </Route>
            </Routes>
        </>
    );
}

export default App;
