import { useContext } from "react";
import { ChartsContext } from "../../contexts/ChartsContext";
import { ReactComponent as SettingsIcon } from "../../assets/sensorAssets/settings-icon.svg";
import { ReactComponent as BackArrow } from "../../assets/insightsIcons/backward-arrow.svg";
import { ReactComponent as ForwardArrow } from "../../assets/insightsIcons/forward-arrow.svg";
import { assign_sensor } from "../../services/api-calls";
import { Context } from "../../contexts/Context";
import { useNavigate } from "react-router-dom";

function ProcessFlowChart() {
    const {
        assetList,
        setAssetList,
        sensorList,
        processFlowData,
        setProcessFlowData,
    } = useContext(ChartsContext);
    const { currentAccessToken } = useContext(Context);

    const navigate = useNavigate();

    function showMenuOf(assetName, key) {
        setAssetList((prevAssetList) =>
            prevAssetList.map((asset) => {
                return asset.name === assetName
                    ? {
                          ...asset,
                          [key]: true,
                      }
                    : asset;
            })
        );
    }

    function goBack(assetName) {
        setAssetList((prevAssetList) =>
            prevAssetList.map((asset) => {
                return asset.name === assetName
                    ? asset.showSensorsList
                        ? {
                              ...asset,
                              showSensorsList: false,
                          }
                        : {
                              ...asset,
                              showSettingsMenu: false,
                          }
                    : asset;
            })
        );
    }

    async function handleChange(e, assetName, sensorName) {
        if (!currentAccessToken) {
            navigate("/", { replace: true });
            return;
        }
        const assetSensorItem = processFlowData.find(
            (item) => item.asset === assetName && item.sensor === sensorName
        );
        const response = await assign_sensor(assetSensorItem);
        setProcessFlowData((prevProcessFlowData) =>
            prevProcessFlowData.map((item) => {
                return item.asset === assetName && item.sensor === sensorName
                    ? response.data.asset_sensor_data
                    : item;
            })
        );
    }

    const absoluteCirclesElements = [
        "top-0 left-1/2 -translate-x-1/2 -translate-y-1/2",
        "right-0 top-1/2 -translate-y-1/2 translate-x-1/2",
        "bottom-0 right-1/2 translate-x-1/2 translate-y-1/2",
        "left-0 bottom-1/2 translate-y-1/2 -translate-x-1/2",
    ].map((divClass) => (
        <div
            className={`absolute w-4 h-4 bg-circleColor rounded-full z-10 ${divClass}`}
        ></div>
    ));

    const assetSquareElements =
        assetList.length > 0 ? (
            assetList.map((asset, assetIndex) => {
                return !asset.showSettingsMenu ? (
                    <div className="relative border-2 border-textColor2 rounded-md w-[220px] h-[220px] flex items-center justify-center bg-bgColor">
                        {absoluteCirclesElements}
                        <SettingsIcon
                            className="absolute right-2 top-2 hover:w-5 hover:h-5 cursor-pointer left"
                            onClick={() =>
                                showMenuOf(asset.name, "showSettingsMenu")
                            }
                        />
                        <p className="text-sm text-textColor text-center">
                            {asset.name}
                        </p>
                    </div>
                ) : (
                    <div
                        className={`relative border-2 border-textColor2 rounded-md w-[220px] h-[220px] flex flex-col items-center justify-start ${
                            asset.showSettingsMenu ? "bg-white" : "bg-bgColor"
                        }`}
                    >
                        {absoluteCirclesElements}
                        <div className="flex items-center justify-center relative w-full bg-bgColor rounded-t-md p-2">
                            <BackArrow
                                className="cursor-pointer absolute left-2"
                                onClick={() => goBack(asset.name)}
                            />
                            <p className="">{asset.name}</p>
                        </div>
                        {!asset.showSensorsList ? (
                            <ul className="flex items-center gap-1 w-full text-sm">
                                <li
                                    className="flex items-center justify-between cursor-pointer p-2 px-4 w-full"
                                    onClick={() =>
                                        showMenuOf(
                                            asset.name,
                                            "showSensorsList"
                                        )
                                    }
                                >
                                    <p>Assign Sensors</p>
                                    <ForwardArrow className="w-3" />
                                </li>
                            </ul>
                        ) : (
                            <div className="self-start p-2 px-4 overflow-y-auto w-full space-y-2">
                                {sensorList.length > 0 ? (
                                    sensorList.map((sensor, index) => (
                                        <div className="flex items-center gap-1">
                                            <input
                                                id={`asset${assetIndex}sensor${index}`}
                                                type="checkbox"
                                                onChange={(e) =>
                                                    handleChange(
                                                        e,
                                                        asset.name,
                                                        sensor
                                                    )
                                                }
                                                checked={
                                                    processFlowData.find(
                                                        (item) =>
                                                            item.asset ===
                                                                asset.name &&
                                                            item.sensor ===
                                                                sensor
                                                    ).is_assigned
                                                }
                                            />
                                            <label
                                                htmlFor={`asset${assetIndex}sensor${index}`}
                                                className="text-xs"
                                            >
                                                {sensor}
                                            </label>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-[10px] text-textColor">
                                        There is no sensor data. Please upload
                                        Sensor Data file to continue.
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                );
            })
        ) : (
            <div>Nothing to show</div>
        );

    return (
        <div
            className={`grid grid-flow-row ${
                assetList.length > 1 ? "grid-cols-3" : ""
            } gap-10 m-auto flex-wrap`}
        >
            {assetSquareElements}
        </div>
    );
}

export default ProcessFlowChart;
