import { useContext, useRef, useState, useEffect } from "react";
import * as d3 from "d3";
import { ChartsContext } from "../../contexts/ChartsContext";

const useResizeObserver = (ref) => {
    const [dimensions, setDimensions] = useState(null);
    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => setDimensions(entry.contentRect));
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);
    return dimensions;
};
function ParetoChart() {
    const { paretoData, createTooltip } = useContext(ChartsContext);

    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        if (!dimensions) return;
        const cumulativeMinutes = paretoData.reduce(
            (sum, currentValue) => sum + currentValue.actualLossTime,
            0
        );

        const sortedParetoData = paretoData.sort(
            (a, b) => b.actualLossTime - a.actualLossTime
        );

        const cumulativeData = sortedParetoData.map((d, i) => ({
            name: d.name,
            value:
                (100 *
                    paretoData
                        .slice(0, i + 1)
                        .reduce((sum, dd) => sum + dd.actualLossTime, 0)) /
                cumulativeMinutes,
        }));
        const xScale = d3
            .scaleBand()
            .domain(sortedParetoData.map((d) => d.name))
            .range([0, dimensions.width])
            .padding(0.5);

        const yScale = d3
            .scaleLinear()
            .domain([0, 100]) // Set the y-scale domain to [0, 100] to represent percentages
            .range([dimensions.height, 0]);

        const xAxis = d3.axisBottom(xScale).tickSize(-dimensions.height);
        const yAxis = d3
            .axisLeft(yScale)
            .tickFormat((d) => `${d}%`)
            .tickSize(-dimensions.width);

        // Add line chart for cumulative data
        const line = d3
            .line()
            .x((d) => xScale(d.name) + xScale.bandwidth() / 2)
            .y((d) => yScale(d.value))
            .curve(d3.curveMonotoneX);

        svg.selectAll(".path")
            .data([cumulativeData])
            .join("path")
            .attr("class", "path")
            .attr("fill", "none")
            .attr("stroke", "#8FD07E")
            .attr("stroke-width", 1)
            .attr("d", line);

        // Add dots to line chart
        svg.selectAll(".dot")
            .data(cumulativeData)
            .join("circle")
            .attr("class", "dot")
            .attr("cx", (d) => xScale(d.name) + xScale.bandwidth() / 2)
            .attr("cy", (d) => yScale(d.value))
            .attr("r", 5)
            .attr("fill", "#8FD07E");

        svg.select(".x-axis")
            .attr("transform", `translate(0, ${dimensions.height})`)
            .call(xAxis)
            .selectAll(".tick text")
            .style("transform", `translate(0, 8px)`);

        svg.select(".y-axis")
            .call(yAxis)
            .selectAll(".tick text")
            .style("transform", `translate(-8px, 0)`);

        svg.selectAll(".tick line").style("stroke", "#D5D5D5");
        svg.selectAll(".domain").style("stroke", "#D5D5D5");

        const tooltip = createTooltip();

        // Two functions that change the tooltip when user hover / leave
        const mouseenter = function (event, phase) {
            tooltip.style("display", "block");
            const newHTML = [
                `<div>
                    ${Number.parseFloat(
                        (100 * phase.actualLossTime) / cumulativeMinutes
                    ).toFixed(2)}%
                </div>`,
            ];
            tooltip
                .html(newHTML)
                .style("left", `${d3.pointer(event)[0]}px`)
                .style("top", d3.pointer(event)[1] + "px");
            d3.select(this).style("stroke", "black").style("opacity", 1);
            event.stopPropagation();
        };

        const mouseleave = function (event) {
            tooltip.style("display", "none");
            d3.select(this).style("stroke", "none");
            event.stopPropagation();
        };

        svg.selectAll(".bar")
            .data(sortedParetoData)
            .join("rect")
            .attr("class", "bar")
            .attr("x", (d) => xScale(d.name))
            .attr("y", (d) =>
                yScale((100 * d.actualLossTime) / cumulativeMinutes)
            ) // Update the y position to be the percentage value
            .attr("width", (d) => xScale.bandwidth())
            .attr(
                "height",
                (d) =>
                    dimensions.height -
                    yScale((100 * d.actualLossTime) / cumulativeMinutes)
            ) // Update the height to be the percentage value
            .attr("fill", "#FF8282")
            .on("mouseenter", mouseenter)
            .on("mouseleave", mouseleave);
    }, [paretoData, dimensions]);

    return (
        <div ref={wrapperRef} className="relative h-96">
            <svg ref={svgRef} className="overflow-visible w-full h-full">
                <g className="x-axis" />
                <g className="y-axis" />
            </svg>
            <div className="tooltip hidden absolute pointer-events-none w-max">
                Hello
            </div>
        </div>
    );
}

export default ParetoChart;
