import React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { check_file_progress, activateFile } from "../../services/api-calls";
import { Context } from "../../contexts/Context";
import Loader from "../../components/loadingComponents/Loader";
import { ReactComponent as CorrectFile } from "../../assets/uploadFileIcons/correct-tick.svg";
import { ReactComponent as Cross } from "../../assets/insightsIcons/cancel-cross.svg";
import { ReactComponent as CorrectTick } from "../../assets/uploadFileIcons/correct-tick.svg";
import toast, { Toaster } from "react-hot-toast";
import { ChartsContext } from "../../contexts/ChartsContext";

export default function ActivateFileModal(props) {
    const { isLoading, setIsLoading } = useContext(Context);
    const { setActivateModal, selectedFile, getFiles } = props;
    const { setIsFileActive } = useContext(ChartsContext);
    const activateSelectedFile = () => {
        setIsLoading(true);
        check_file_progress()
            .then((res) => {
                if (res.data.upload_in_progress === true) {
                    setIsLoading(false);
                    toast.error(
                        "File is already in progress in the background."
                    );
                } else if (res.data.sensor_upload_in_progress === true) {
                    setIsLoading(false);
                    toast.error(
                        "File is already in progress in the background"
                    );
                } else {
                    let fileDat = {
                        file_id: selectedFile._id,
                        file_type: selectedFile.file_type,
                    };
                    activateFile(fileDat)
                        .then((res) => {
                            setIsLoading(false);
                            toast.success("File is activated successfully");
                            // checkProgress();
                            setIsFileActive(true);
                            getFiles();
                            setActivateModal(false);
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            setActivateModal(false);
                            // toast.error("Something went wrong please try again");
                        });
                }
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error("Something went wrong. Please try again");
            });
    };

    return (
        <div className="flex justify-center items-center fixed top-10 left-10 w-full h-full">
            <div className="w-full h-full absolute bg-gray-400 z-10 opacity-80 flex justify-center items-center"></div>
            <div className="absolute z-20 opacity-100 bg-white shadow-xl rounded-md p-5 px-8 flex flex-col items-center max-h-[80vh]">
                <div className="flex justify-end w-full">
                    <Cross
                        className=" w-4 h-4 cursor-pointer"
                        onClick={() => {
                            setActivateModal(false);
                        }}
                    />
                </div>
                <div className="flex flex-col justify-center items-center">
                    <CorrectTick className=" w-16 h-16" />
                    <span className=" text-lg text-center pt-3">
                        Are you sure you want to activate this file?
                    </span>
                    <p className=" text-green-600 pb-4">
                        {selectedFile.original_file_name}
                    </p>
                    <div className="flex justify-center">
                        <button
                            className=" shadow-lg p-1 px-10 rounded-md ml-4 h-8"
                            onClick={() => {
                                setActivateModal(false);
                            }}
                        >
                            No
                        </button>
                        <button
                            className="bg-green-700 text-white p-1 px-10 rounded-md ml-4 h-8 shadow-md"
                            onClick={activateSelectedFile}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
